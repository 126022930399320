import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toastify } from "utils/toastify";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import { EnumsService, VehicleModelsService, VehicleBrandsService, CountriesService, RidesService } from "data/services";

const TransactionRideStatusModal = (props) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors
    } = useForm();
    const [country, setCountry] = useState(null);
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [transactionData, setTransactionData] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const setBankAccountData = async () => {
            const bankAccountData = props.data;
            const countryData = await CountriesService.getById(bankAccountData.countryId);
            setCountry(countryData.data);
            if (bankAccountData) {
                bankAccountData.countryName = countryData?.data?.name;
                reset(bankAccountData);
                setTransactionData(bankAccountData);
            } else {
                props.onDiscard();
            }
        };
        setBankAccountData();
    }, []);

    const onSubmit = async (data) => {
        var model = {
            transactionStatus: data.transactionStatus,
            rideId: data.rideId,
            bankAccountTransactionData: {
                id: props.data.bankAccountTransactionId,
                nameAccountOwner: data.nameAccountOwner,
                accountNumber: data.accountNumber,
                countryId: data.countryId,
                address: data.address,
                rideId: data.rideId,
                bankName: data.bankName,
                IBAN: data.iban,
                SWIFT: data.swift,
                total: data.total,
                rideFee: data.rideFee,
                bankFee: data.bankFee
            }
        }
        setFormSubmitting(true);
        const editedRideTransactionStatus = await RidesService.changeTransactionStatus(model);
        if (editedRideTransactionStatus.statusCode === 409) {
            setFormSubmitting(false);
            return;
        }
        setFormSubmitting(false);
        if (editedRideTransactionStatus.isSuccess) {
            props.onSuccess(true);
            toastify.success(t("Uspješno ste promjenili podatke o plaćanju"));
        } else {
            toastify.error(t("Greška prilikom promjene podataka o plaćanju"));
        }
    };

    const handleDiscard = () => {
        props.onDiscard();
    }
    return (
        <>
            <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="card shadow mw-100" style={isMobile ? { width: "90%", maxHeight: "90vh" } : { width: "30%", maxHeight: "90vh" }}>
                        <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                            <span className="headers">{props.title}</span>
                            <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => handleDiscard()}><svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none"><path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col d-flex flex-column'>
                                        <label className="labels mb-2 ms-0">{t("FIRST_LAST_NAME")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0' >  <i className="bi bi-person"></i> {transactionData?.nameAccountOwner}</p>
                                        </div>
                                    </div>
                                    {props.data.transactionStatus === "Paid" || props.data.transactionStatus === "Check" ? <div className='col d-flex flex-column'>
                                        <div className=' d-flex justify-content-start'>
                                            <label className="labels mb-2 ms-0">{t("ACCOUNT_NUMBER")}:</label>
                                        </div>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0' >  <i className="bi bi-cash-coin"></i> {transactionData?.accountNumber} </p>
                                        </div>
                                    </div> :
                                        <div className="col">
                                            <div className='d-flex justify-content-start'>
                                                <label className="labels mb-2">{t("ACCOUNT_NUMBER")}:</label>
                                            </div>
                                            <input  {...register("accountNumber", { required: t("ACCOUNT_NUMBER_REQUIRED") })} id='accountNumber'
                                                className="form-control" placeholder={t("ENTER_ACCOUNT_NUMBER")} name='accountNumber'
                                                disabled={props.data.transactionStatus === "Paid" || props.data.transactionStatus === "Check"} />
                                            {errors.accountNumber && <span className="text-danger">{t("ACCOUNT_NUMBER_REQUIRED")}</span>}</div>}
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col d-flex flex-column'>
                                        <label className="labels mb-2 ms-0">{t("COUNTRY")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0' >  <i className="bi bi-flag"></i> {transactionData?.countryName}</p>
                                        </div>
                                    </div>
                                    <div className='col d-flex flex-column'>
                                        <label className="labels mb-2 ms-0">{t("RESIDENTAL_ADDRESS")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0' >  <i className="bi bi-geo-alt"></i> {transactionData?.address}</p>
                                        </div>
                                    </div>
                                </div>
                                {country != null && country.abbreviation !== 'BiH' &&
                                    <div>
                                        <div className='row mt-3'>
                                            <div className='col d-flex flex-column'>
                                                <label className="labels mb-2 ms-0">{t("BANK")}:</label>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <p className='paragraphs mb-0' >  <i className="bi bi-bank"></i> {transactionData?.bankName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row justify-content-between mt-3'>
                                            <div className='col d-flex flex-column'>
                                                <label className="labels mb-2 ms-0">{t("IBAN")}:</label>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <p className='paragraphs mb-0' >  <i className="bi bi-hash"></i> {transactionData?.iban}</p>
                                                </div>
                                            </div>
                                            <div className='col d-flex flex-column'>
                                                <label className="labels mb-2 ms-0">{t("SWIFT")}:</label>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <p className='paragraphs mb-0' >  <i className="bi bi-list"></i> {transactionData?.swift}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='row justify-content-between mt-3'>
                                    {props.data.transactionStatus === "Paid" || props.data.transactionStatus === "Check" ? <div className='col d-flex flex-column'>
                                        <div className=' d-flex justify-content-start'>
                                            <label className="labels mb-2 ms-0">{t("PROPOSED_AMOUNT")}:</label>
                                        </div>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h5 className="BAM-big-purple "> {transactionData?.total} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                        </div>
                                    </div> :
                                        <>
                                            <div className=' d-flex justify-content-start'>
                                                <label className="labels mb-2">{t("PROPOSED_AMOUNT")}:</label>
                                            </div>
                                            <div className="col d-flex">
                                                <input {...register("total")} id='total' type="number" step="any" className="form-control me-2" name='total'
                                                    disabled={props.data.transactionStatus === "Paid" || props.data.transactionStatus === "Check"} />
                                                <h5 className="mt-2" style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                            </div>
                                        </>}
                                </div>
                                {(props.data.transactionStatus != "Paid" && props.data.transactionStatus != "Check") && <div className='row mt-3'>
                                    <div className='col'></div>
                                    <div className='col-auto'>
                                        <button className="btn text-secondary border-0 float-end" onClick={() => props.onDiscard()}>{t("DISCARD")}</button>
                                    </div>
                                    <div className='col-auto'>
                                        <button className="btn btn-primary text-white" type='submit'>
                                            {t("SAVE")}
                                        </button>
                                    </div>
                                </div>}
                                {(props.data.transactionStatus === "Paid" || props.data.transactionStatus === "Check") &&
                                    <div className="d-flex justify-content-end">
                                        <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => props.onDiscard()}>
                                            {t("CLOSE")}
                                        </button>
                                    </div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );

};
export default TransactionRideStatusModal;