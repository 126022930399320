import Flatpickr from "react-flatpickr";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import "./hero.css"
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { FromIcon, ToIcon } from "components/layout/components/Icons";
import { useTranslation } from "react-i18next";
import { AppSettingsService, RideMilestonesService } from "data/services";
import heroSearch from "data/stores/HeroSearchStore";
import { CountryFlag } from "components/common/countryFlag";
import { reactSelectCustomStyle } from "assets/styles/react-select-customstyle";
import moment from "moment";
import globalStore from "data/stores/GlobalStore";
import { observer } from "mobx-react-lite";
import { merge } from "lodash";

const HeroSearchComponent = observer(({ colN }) => {
    const { t } = useTranslation();
    const [seats, setSeats] = useState();
    const [date, setDate] = useState();
    const [defaultFetched, setDefaultFetched] = useState(false);
    const [countryFlags, setCountryFlags] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    let currentParams = Object.fromEntries([...searchParams]);
    const flatpickrRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [locationFrom, setLocationFrom] = useState(null);
    const [locationTo, setLocationTo] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
    let [maxPassengersCount, setMaxPassengersCount] = useState(1);
    const [cachedData, setCachedData] = useState([]);
    const [errValidationAllLocations, setErrValidationAllLocations] = useState(false);
    const asyncSelectRefFrom = useRef(null);
    const asyncSelectRefTo = useRef(null);
    const [reloadOptionsKey, setReloadOptionsKey] = useState(0);
    const [maxNumberOfSeats, setMaxNumberOfSeats] = useState(4);
    const [options, setOptions] = useState([]);
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        if (location.state != null) {
            setCountryFlags(location.state.countryFlags)
        }
        if (location.state == null && location.pathname === '/rides') {
            const search = {
                cityNameFrom: currentParams.cityNameFrom,
                cityNameTo: currentParams.cityNameTo
            }
            const fetchAndSetFlags = async () => {
                const response = await RideMilestonesService.getFlags(search);
                if (response.isSuccess) {
                    setCountryFlags(response.data)
                }
            }
            fetchAndSetFlags();

        }
        const fetchAndSetMaxNumberOfSeats = async () => {
            const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
            setMaxNumberOfSeats(response.data.items[0].maximumNumberOfSeats);
          }
          fetchAndSetMaxNumberOfSeats();
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        currentParams = Object.fromEntries([...searchParams]);
        setSeats(currentParams.passengersCount ? setMaxPassengersCount(+currentParams.passengersCount) : setMaxPassengersCount(1))
        setDate(currentParams.startDate ? moment(currentParams.startDate, 'MM-DD-YYYY').format('DD.MM.YYYY') : '');
        const locationFromValue = currentParams && currentParams.cityNameFrom != null ?
            {
                label: currentParams.cityNameFrom === 'all' ? t('ALL_LOCATIONS') : currentParams.cityNameFrom,
                value: currentParams.cityNameFrom
            } : null;
        register('locationFrom', { required: true, value: locationFromValue });
        const locationToValue = currentParams && currentParams.cityNameTo != null ?
            {
                label: currentParams.cityNameTo === 'all' ? t('ALL_LOCATIONS') : currentParams.cityNameTo,
                value: currentParams.cityNameTo
            } : null;
        register('locationTo', { required: true, value: locationToValue });
        setLocationFrom(getValues('locationFrom'));
        setLocationTo(getValues('locationTo'));
        const search = {
            cityNameFrom: currentParams.cityNameFrom,
            cityNameTo: currentParams.cityNameTo
        }
        const fetchAndSetFlags = async () => {
            const response = await RideMilestonesService.getFlags(search);
            if (response.isSuccess) {
                if (response.data.locationFrom != null && response.data.locationTo != null) {
                    setCountryFlags(response.data);
                    asyncSelectRefFrom.current.props.onChange({ value: currentParams.cityNameFrom, label: currentParams.cityNameFrom, countryCode: response.data.locationFrom.countryCode, country: response.data.locationFrom.country });
                    asyncSelectRefTo.current.props.onChange({ value: currentParams.cityNameTo, label: currentParams.cityNameTo, countryCode: response.data.locationTo.countryCode, country: response.data.locationTo.country });
                }
                if(response.data.locationFrom === null && currentParams.cityNameFrom!=undefined)
                {
                    searchParams.set('cityNameFrom', 'all');
                    asyncSelectRefFrom.current.props.onChange({ value: "all", label: t("ALL_LOCATIONS"), countryCode: "all_locations", country: null });
                }
                if(response.data.locationTo === null && currentParams.cityNameTo!=undefined)
                {
                    searchParams.set('cityNameTo', 'all');
                    asyncSelectRefTo.current.props.onChange({ value: "all", label: t("ALL_LOCATIONS"), countryCode: "all_locations", country: null });
                }
                setSearchParams(searchParams);
            }
        }
        fetchAndSetFlags();

    }, [searchParams])

    const reloadOptions = () => {
        setReloadOptionsKey(prevKey => prevKey + 1);
    };

    useLayoutEffect(() => {
        const translateItems = async () => {
            if (locationFrom?.value == "all") {
                locationFrom.label = t("ALL_LOCATIONS");
                setValue("locationFrom", locationFrom);
                asyncSelectRefFrom.current.props.onChange(locationFrom);
            }
            if (locationTo?.value == "all") {
                locationTo.label = t("ALL_LOCATIONS");
                setValue("locationTo", locationTo);
                asyncSelectRefTo.current.props.onChange(locationTo);
            }
            reloadOptions();
        }
        translateItems();
    }, [globalStore.lng]);


    const FilterData = async (e) => {
        let response;
        let temp = [];
        if (defaultFetched == false || globalStore.changeLanguage) {
            temp.push({ value: "all", label: t("ALL_LOCATIONS"), countryCode:"all_locations" });
            response = await RideMilestonesService.getTopLocations(5);
            response.data.forEach(r => {
                temp.push({ value: r.key, label: r.key, countryCode: r.countryCode, country: r.country });
            });
            setDefaultFetched(true);
            globalStore.setChangeLanguage(false);
        }
        else {
            response = await RideMilestonesService.getByParams({ cityName: e, pageNumber: 1, pageSize: 999 });
            response.data.items.forEach(r => {
                temp.push({ value: r.cityName, label: r.cityName, countryCode: r.countryCode, country: r.country });
            });
        }
        setOptions(temp);
        return temp;
    }

    const HandleFormSubmit = async (data) => {
        if (data.locationFrom.value === "all" && data.locationTo.value === "all") {
            setErrValidationAllLocations(true);
            return;
        }
        else {
            setErrValidationAllLocations(false);
        }

        const timestamp = new Date().getTime();
        heroSearch.setFilters({ ...heroSearch.filteredData, ...data, seats: maxPassengersCount, date: date });
        const queryString = {
            cityNameFrom: heroSearch.filteredData.locationFrom.value !== "all" ? `cityNameFrom=${heroSearch.filteredData.locationFrom.label}` : `cityNameFrom=all`,
            cityNameTo: heroSearch.filteredData.locationTo.value != "all" ? `&cityNameTo=${heroSearch.filteredData.locationTo.label}` : `&cityNameTo=all`,
            passengersCount: heroSearch.filteredData.seats,
            startDate: heroSearch.filteredData.date ? `&startDate=${moment(heroSearch.filteredData.date, 'DD.MM.YYYY').format('MM-DD-YYYY')}` : ``,
            timestamp: `&timestamp=${timestamp}`
        }
        navigate({ pathname: '/rides', search: `${queryString.cityNameFrom}${queryString.cityNameTo}${queryString.startDate}&passengersCount=${queryString.passengersCount}${queryString.timestamp}` }, { state: { countryFlags } });
    }

    const handleClear = () => {
        flatpickrRef.current.flatpickr.clear();
    };
    const changeInput = () => {
        const locationFromValue = getValues('locationFrom');
        const locationToValue = getValues('locationTo');
        setValue('locationFrom', locationToValue);
        setValue('locationTo', locationFromValue);
        setLocationFrom(locationToValue);
        setLocationTo(locationFromValue);
        if (locationFromValue == null && locationToValue == null) {
            return;
        }
        if (locationFromValue == null) {
            setCountryFlags(prev => {
                return {
                    locationFrom: { ...prev?.locationTo }
                }
            })

        }
        else if (locationToValue == null) {
            setCountryFlags(prev => {
                return {
                    locationTo: { ...prev?.locationFrom }
                }
            })

        }
        else {
            setCountryFlags(prev => {
                return {
                    locationTo: { ...prev?.locationFrom },
                    locationFrom: { ...prev?.locationTo }
                }
            })
        }
    }

    const decrementCount = () => {
        if (maxPassengersCount > 1) {
            maxPassengersCount = maxPassengersCount - 1;
            setMaxPassengersCount(maxPassengersCount);
        }
    }

    const incrementCount = () => {
        if (maxPassengersCount < maxNumberOfSeats) {
            maxPassengersCount = maxPassengersCount + 1;
            setMaxPassengersCount(maxPassengersCount);
        }
    }

    return (
        <>
            <style>
                {`
                @media screen and (max-width: 767px) {
                    .flatpickr-calendar {
                    left: 10% !important;
                    }
                }
                .css-13cymwt-control {
                    border-radius: 0px 8px 8px 0px !important;
                  }
                  .css-t3ipsp-control{
                    border-radius: 0px 8px 8px 0px !important;
                  }
        `}
            </style>
            <div className="row justify-content-center  mt-n5 ">
                <div className={`col-${colN} mt-lg-n5 mt-2 `}>
                    <form onSubmit={handleSubmit(HandleFormSubmit)} className="card rounded-3 mt-sm-3 mt-4 p-4 px-2 pe-md-2 pb-md-4" style={{boxShadow:"0px 10px 20px rgba(29, 58, 83, 0.1)"}}>
                        <div className="d-flex" style={{ paddingLeft: "14px" }}>
                            <h5 style={{ color: "black" }}>{t("WHERE_TO")}?</h5>
                            <div className="d-flex col">
                                <div style={{ borderBottom: "2px solid rgb(240, 240, 240)", flexGrow: 1, maxHeight: "50%", marginLeft: "15px" }}></div>
                            </div>
                        </div>
                        <div className="row justify-content-between px-4 " >
                            <div className="col-lg  px-1 ">
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1 ">
                                        <label className="mb-1 small">{t("LEAVING_FROM")}:</label>
                                        <div className="input-group flex-row justify-content-center">
                                            <div className={`input-group-prepend col-lg-3 col-sm-2 ${isMobile ? 'w-20' : 'w-25'}`}>
                                                <span className="icon input-group-text">
                                                    <i style={{ opacity: "50%", marginBottom: "5px" }}>
                                                        <FromIcon size="20px"></FromIcon>
                                                    </i>
                                                    {countryFlags && countryFlags.locationFrom &&
                                                        <CountryFlag className="ms-2" tooltip={"search"} code={countryFlags.locationFrom?.countryCode?.toLowerCase()} fullName={countryFlags.locationFrom?.country} />
                                                    }
                                                </span>
                                            </div>
                                            <AsyncSelect
                                                key={reloadOptionsKey}
                                                ref={asyncSelectRefFrom}
                                                styles={reactSelectCustomStyle}
                                                name="locationFrom"
                                                className={`basic-single myAsyncSelect col-lg-9 col-sm-10 ${isMobile ? 'w-80' : 'w-75'}`}
                                                value={locationFrom}
                                                onChange={(selectedOption) => {
                                                    if (errValidationAllLocations) {
                                                        setErrValidationAllLocations(false);
                                                    }
                                                    setLocationFrom(selectedOption);
                                                    setValue("locationFrom", selectedOption);
                                                    if (selectedOption != null) {
                                                        setCountryFlags(prev => {
                                                            return {
                                                                ...prev,
                                                                locationFrom: {
                                                                    ...selectedOption
                                                                }
                                                            }
                                                        })
                                                    } else {
                                                        if (countryFlags && countryFlags.locationTo != null) {
                                                            setCountryFlags({ locationTo: countryFlags.locationTo })
                                                        } else {
                                                            setCountryFlags()
                                                        }
                                                    }
                                                }}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                                loadingMessage={() => t("LOADING")}
                                                defaultValue={currentParams && currentParams.cityNameFrom != null ? { label: currentParams.cityNameFrom } : null}
                                                loadOptions={(e) => FilterData(e)}
                                                placeholder={t("FROM2")}
                                                defaultOptions={true}
                                                options={options}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                    {errors.locationFrom && <span className="text-danger small">{t("ERROR_SELECT_LOCATION")}</span>}
                                </div>
                            </div>
                            {!isMobile ? <div className="col-lg-auto" style={{ marginLeft: "-10px", marginRight: "-10px", marginTop: "28px", zIndex: "1" }}>
                                <div className="btn btn-sm btn-primary" style={{ borderRadius: "10px" }} onClick={() => changeInput()}>
                                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 3.6333H13.0334C14.4167 3.6333 15.5334 4.74997 15.5334 6.1333V8.89997" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.13334 1L1.5 3.63331L4.13334 6.26667" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.5334 15.0334H4C2.61667 15.0334 1.5 13.9167 1.5 12.5334V9.76672" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.8999 17.6667L15.5332 15.0334L12.8999 12.4" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div> :
                                <>
                                    <div className="col"></div>
                                    <div className="col-auto" >
                                        <div className="button-replace btn btn-sm btn-primary" onClick={() => changeInput()}>
                                            <svg width="17" height="17" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 3.6333H13.0334C14.4167 3.6333 15.5334 4.74997 15.5334 6.1333V8.89997" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.13334 1L1.5 3.63331L4.13334 6.26667" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5334 15.0334H4C2.61667 15.0334 1.5 13.9167 1.5 12.5334V9.76672" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.8999 17.6667L15.5332 15.0334L12.8999 12.4" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="col"></div>
                                </>
                            }
                            <div className="col-lg px-1">
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1 ">
                                        <label className="mb-1 small">{t("GOING")}:</label>
                                        <div className="input-group flex-row">
                                            <div className={`input-group-prepend col-lg-3 col-sm-2 ${isMobile ? 'w-20' : 'w-25'}`}>
                                                <span className="icon input-group-text">
                                                    <i style={{ opacity: "50%", marginBottom: "5px" }}>
                                                        <ToIcon size="20px"></ToIcon>
                                                    </i>
                                                    {countryFlags && countryFlags.locationTo &&
                                                        <CountryFlag className="ms-2" tooltip={"search"} code={countryFlags.locationTo?.countryCode?.toLowerCase()} fullName={countryFlags.locationTo?.country} />
                                                    }
                                                </span>
                                            </div>
                                            <AsyncSelect
                                                key={reloadOptionsKey}
                                                ref={asyncSelectRefTo}
                                                name="locationTo"
                                                value={locationTo}
                                                styles={reactSelectCustomStyle}
                                                className={`basic-single myAsyncSelect col-lg-9 col-sm-10 ${isMobile ? 'w-80' : 'w-75'}`}
                                                onChange={(selectedOption) => {
                                                    if (errValidationAllLocations) {
                                                        setErrValidationAllLocations(false);
                                                    }
                                                    setLocationTo(selectedOption)
                                                    setValue("locationTo", selectedOption)
                                                    if (selectedOption != null) {
                                                        setCountryFlags(prev => {
                                                            return {
                                                                ...prev,
                                                                locationTo: {
                                                                    ...selectedOption
                                                                }
                                                            }
                                                        })
                                                    } else {
                                                        if (countryFlags && countryFlags.locationFrom != null) {
                                                            setCountryFlags({ locationFrom: countryFlags.locationFrom })
                                                        } else {
                                                            setCountryFlags()
                                                        }
                                                    }
                                                }}
                                                isClearable={true}
                                                defaultOptions={true}
                                                defaultValue={currentParams && currentParams.cityNameTo != null ? { label: currentParams.cityNameTo } : null}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                                loadingMessage={() => t("LOADING")}
                                                loadOptions={(e) => FilterData(e)}
                                                placeholder={t("TO2")}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                    {errors.locationTo && <span className="text-danger small">{t("ERROR_SELECT_LOCATION")}</span>}
                                </div>
                                {errValidationAllLocations && isMobile && <span className="text-danger small">{t("ALL_LOCATIONS_ERR")}</span>}
                            </div>

                            {!isMobile ? <div className="px-1" style={{ width: "200px" }} >
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1 ">
                                        <label className="mb-1 small">{t("DATE")}:</label>
                                        <div className="input-group flex-row">
                                            <div className="input-group-prepend">
                                                <span className="icon input-group-text ">
                                                    <svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.16675 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.8333 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3.85425 8.7113H20.1459" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M20.625 8.14579V16.2916C20.625 19.1666 19.1875 21.0833 15.8333 21.0833H8.16667C4.8125 21.0833 3.375 19.1666 3.375 16.2916V8.14579C3.375 5.27079 4.8125 3.35413 8.16667 3.35413H15.8333C19.1875 3.35413 20.625 5.27079 20.625 8.14579Z" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.5408 13.1292H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.5408 16.0042H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.9956 13.1292H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.9956 16.0042H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.44876 13.1292H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.44876 16.0042H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <Flatpickr
                                                options={{ disableMobile: true, minDate: "today", dateFormat: "d.m.Y" }}
                                                onInput={(e) => setDate(e.target.value)}
                                                id="myFlatPickr"
                                                type="date"
                                                ref={flatpickrRef}
                                                className="form-control flatpickr col-lg-8 col-sm-11 col-10"
                                                value={currentParams.startDate ? moment(currentParams.startDate, 'MM-DD-YYYY').format('DD.MM.YYYY') : ''}
                                                placeholder={t("DATE")} readOnly="readonly"
                                                style={{marginLeft:"-5px"}} />
                                            <div className="custom-datapickr-clear">
                                                <div className="css-1xc3v61-indicatorContainer" onClick={() => handleClear()} aria-hidden="true"><svg height="20" width="20" fill="#cccccc" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-8mmkcg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> :
                                <div className="col-lg-3 px-1">
                                    <div className="flex-grow-1">
                                        <div className="flex-grow-1 ">
                                            <label className="mb-1 small">{t("DATE")}:</label>
                                            <div className="input-group flex-row">
                                                <div className="input-group-prepend">
                                                    <span className="icon input-group-text ">
                                                        <svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.16675 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.8333 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.85425 8.7113H20.1459" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M20.625 8.14579V16.2916C20.625 19.1666 19.1875 21.0833 15.8333 21.0833H8.16667C4.8125 21.0833 3.375 19.1666 3.375 16.2916V8.14579C3.375 5.27079 4.8125 3.35413 8.16667 3.35413H15.8333C19.1875 3.35413 20.625 5.27079 20.625 8.14579Z" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.5408 13.1292H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.5408 16.0042H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.9956 13.1292H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.9956 16.0042H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.44876 13.1292H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.44876 16.0042H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <Flatpickr
                                                    options={{ disableMobile: true, minDate: "today", dateFormat: "d.m.Y" }}
                                                    onInput={(e) => setDate(e.target.value)}
                                                    id="myFlatPickr"
                                                    type="date"
                                                    ref={flatpickrRef}
                                                    className="form-control flatpickr col-lg-8 col-sm-11 col-10"
                                                    value={currentParams.startDate ? moment(currentParams.startDate, 'MM-DD-YYYY').format('DD.MM.YYYY') : ''}
                                                    placeholder={t("DATE")} readOnly="readonly" 
                                                    style={{paddingLeft:"9px"}}/>

                                                <div className="custom-datapickr-clear">
                                                    <div className="css-1xc3v61-indicatorContainer" onClick={() => handleClear()} aria-hidden="true"><svg height="20" fill="#cccccc" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                            <div className="col-lg-auto  px-1">
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1">
                                        <label className="mb-1 small">{t("SEATS")}:</label>
                                        {!isMobile ? <div className="input-group flex-row">
                                            <div className="input-group-prepend col-lg-4 col-sm-1 col-2">
                                                <span className="icon input-group-text ">
                                                    <i style={{ marginBottom: "3px" }}>
                                                        <svg width="21" height="20" style={{ marginTop: "-2px" }} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </i>
                                                </span>
                                            </div>
                                            <div className="passenger-div">
                                                <span
                                                    type="button"
                                                    className="minus ms-2"
                                                    onClick={decrementCount}
                                                ><i className="fa-solid flex-center increment-decrement ms-0 fa-minus "></i></span>
                                                <span className="ms-2 mt-2" style={{ fontSize: "16px", color: "black", fontWeight: "500" }}>{maxPassengersCount}</span>
                                                <span
                                                    type="button"
                                                    onClick={incrementCount}
                                                    className="plus  ms-2 mt-2 me-2"
                                                ><i className="fa-solid increment-decrement ms-0 flex-center  fa-plus "></i></span>
                                            </div>
                                        </div> : <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="icon input-group-text">
                                                    <svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="passenger-div col">
                                                <span
                                                    type="button"
                                                    className="minus ms-2"
                                                    style={{ marginTop: "9px", display: "inline-block" }}
                                                    onClick={decrementCount}
                                                ><i className="fa-solid fa-minus flex-center increment-decrement"></i></span>
                                                <span
                                                    className="ms-2"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "black",
                                                        fontWeight: "500",
                                                        display: "inline-block",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >{maxPassengersCount}</span>
                                                <span
                                                    type="button"
                                                    onClick={incrementCount}
                                                    className="plus ms-2"
                                                    style={{ marginTop: "9px", display: "inline-block" }}
                                                ><i className="fa-solid fa-plus flex-center increment-decrement"></i></span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto mt-3 px-1 ">
                                <div className="flex-grow-1">
                                    {!isMobile ? <button type="submit" className="mybtn backgroundColor" >
                                        <div className="d-flex flex-row mt-1" >
                                            <svg width="17" height="16" viewBox="0 0 20 20" style={{ marginTop: "3px" }} fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_202_1401)">
                                                    <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M18.889 18.8889L13.5557 13.5555" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_202_1401">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className="col-9 mb-0 px-0 mb-2" style={{ fontSize: "15px", marginTop: "2px", fontWeight: "400", marginLeft: "5px" }} >{t("SEARCH")}</p>
                                        </div>
                                    </button> : <button type="submit" className="mybtn backgroundColor" style={{ height: "45px" }}>
                                        <div className="row  mt-1">
                                            <div className="col"></div>
                                            <div className="col-1">
                                                <svg width="19" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_202_1401)">
                                                        <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M18.889 18.8889L13.5557 13.5555" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_202_1401">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="col-auto">
                                                <p className="mb-2" style={{ marginTop: "2px", fontWeight: "400", marginLeft: "5px" }}>{t("SEARCH")}</p>
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                    </button>}
                                </div>
                            </div>
                        </div>
                        {errValidationAllLocations && !isMobile && <span className="text-danger small ms-3">{t("ALL_LOCATIONS_ERR")}</span>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default HeroSearchComponent;