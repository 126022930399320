import { observer } from "mobx-react-lite"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import { httpSmartClient } from "config/httpClient"
import { useState } from "react"
import { getAppPagingSettings } from "config/paging"
import { PagingComponent } from "components/layout/components"
import { Spinner } from "components/common/Spinner"
import { RideMilestonesService, RidesService } from "data/services"

const CatalogPageMainComponentMobile = observer(({ ...AllRide }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [data, setData] = useState();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);
    const [countryFlags, setCountryFlags] = useState();
    let pagingSettings = getAppPagingSettings();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const [previousSearchParams, setPreviousSearchParams] = useState();
    const [selectedOption, setSelectedOption] = useState();

    const orderBy = [
        {
            value: 1,
            label: t("LOWEST_PRICE")
        },
        {
            value: 0,
            label: t("EARLIEST_DEPARTURE_TIME")
        },
    ]

    useEffect(() => {
        if (currentParams.listingOrder != null) {
            setSelectedOption(currentParams.listingOrder == 0 ? t("EARLIEST_DEPARTURE_TIME") : t("LOWEST_PRICE"))
        }
        else {
            setSelectedOption(null);
        }
        location.state && setCountryFlags(location.state.countryFlags);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTablet(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [searchParams]);

    useEffect(() => {
        const updatedPagingData = searchParams !== previousSearchParams
            ? { ...pagingData, pageNumber: 1 }
            : pagingData;

        const conditionIdsParams = searchParams.getAll('conditionsIds');
        const conditionIdsIntList = conditionIdsParams.map((value) => parseInt(value));
        const queryString = {
            pageNumber: updatedPagingData.pageNumber,
            pageSize: updatedPagingData.pageSize,
            ...currentParams, conditionsIds: conditionIdsIntList.join(',')
        };

        const fetchAndSetRides = async () => {
            if (queryString.cityNameFrom === "all") {
                queryString.cityNameFrom = null;
            }
            if (queryString.cityNameTo === "all") {
                queryString.cityNameTo = null;
            }
            setIsLoading(true);
            const response = await RidesService.getRidesByQuery(queryString);
            if (response) {
                setData(response);
                setIsLoading(false);
            }
        }
        fetchAndSetRides();
        const search = {
            cityNameFrom: currentParams.cityNameFrom,
            cityNameTo: currentParams.cityNameTo
        }
        if (countryFlags == null) {

            const fetchAndSetFlags = async () => {
                const response = await RideMilestonesService.getFlags(search);
                if (response.isSuccess) {
                    setCountryFlags(response.data)
                }
            }
            fetchAndSetFlags();
        }
        setPreviousSearchParams(searchParams);
        window.scrollTo(0, 0);
    }, [searchParams, pagingData.pageNumber, pagingData.pageSize]);

    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }

    if (isLoading) {
        return (
            <Spinner />
        )
    }

    const RideDetail = (ride) => {
        navigate({ pathname: `/rides/${ride.id}/${ride.fromMilestoneId}/${ride.toMilestoneId}` });
    }

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleChangeOrder = (value, label) => {
        const formHour = currentParams.fromHour != null ? `&fromHour=${currentParams.fromHour}` : ``;
        const toHour = currentParams.toHour != null ? `&toHour=${currentParams.toHour}` : ``;
        const minPrice = currentParams.minPrice != null ? `&minPrice=${currentParams.minPrice}` : ``;
        const maxPrice = currentParams.maxPrice != null ? `&maxPrice=${currentParams.maxPrice}` : ``;
        const startDate = currentParams.startDate != null ? `&startDate=${currentParams.startDate}` : ``;
        const conditions = currentParams.conditionsIds != null ? `&conditionsIds=${currentParams.conditionsIds}` : ``;
        setSelectedOption(label);
        navigate({ pathname: '/rides', search: `cityNameFrom=${currentParams.cityNameFrom}&cityNameTo=${currentParams.cityNameTo}${startDate}&passengersCount=${currentParams.passengersCount}${formHour}${toHour}${minPrice}${maxPrice}${conditions}&listingOrder=${value}` });
    }
    return (
        <>
            <div className="row mb-2" style={{ marginTop: "-15px" }}>
                <div className="col-auto">
                    {data && data.totalCount > 0 && <h5 style={{ fontSize: "15px", fontWeight: "bold", color: "black", marginTop: "10px" }}>{t("RIDES_AVAILABLE")}: {data && data && data.totalCount}</h5>}
                </div>
                <div className="col"></div>
                <div className="col-auto d-flex flex-row" style={{ marginTop: "11px", marginRight: "-32px" }}>
                    <h6 className="mb-1" style={{ color: "#900FC8", fontWeight: "bold", fontSize: "15px" }} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebarFilters" aria-controls="offcanvasSidebar" onClick={handleScrollToTop}>
                        <svg width="19" height="19" style={{ marginRight: "5px" }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="filter">
                                <path id="Vector" d="M4.95052 1.92529H17.0505C18.0589 1.92529 18.8839 2.75029 18.8839 3.75863V5.77529C18.8839 6.50863 18.4255 7.42529 17.9672 7.88363L14.0255 11.367C13.4755 11.8253 13.1089 12.742 13.1089 13.4753V17.417C13.1089 17.967 12.7422 18.7003 12.2839 18.9753L11.0005 19.8003C9.80885 20.5336 8.15885 19.7086 8.15885 18.242V13.3836C8.15885 12.742 7.79219 11.917 7.42552 11.4586L3.94219 7.79196C3.48385 7.33363 3.11719 6.50863 3.11719 5.95863V3.85029C3.11719 2.75029 3.94219 1.92529 4.95052 1.92529Z" stroke="#900FC8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_2" d="M10.0192 1.92529L5.5 9.16696" stroke="#900FC8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </svg>
                        {t("FILTERS")}
                    </h6>
                </div>
            </div>
            {data.items == 0 &&
                <>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-auto"> <img src="/assets/images/search.svg" style={{ width: "400px", height: "300px" }} /></div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-auto ms-2 mb-4" style={{ textAlign: "center" }}>
                            <h4 className="mt-0" style={{ color: "purple" }}>{t("UPS_NO_RESULT")}</h4>
                            <h5 className="form-label" style={{}}>{t("PLEASE_TRY_ANOTHER_SEARCH")}</h5>
                        </div>
                        <div className="col"></div>
                    </div>
                </>
            }
            {data &&
                data.items &&
                data.items.map((ride, index) => {
                    const toDate = moment.utc(new Date(ride.startDateTime))
                    const fromDate = moment.utc(new Date(ride.endDateTime))
                    const minutes = fromDate.diff(toDate, "minutes")
                    const hours = fromDate.diff(toDate, "hours");
                    const min = minutes % 60;
                    ride.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}min` : `${min}min`
                    return (
                        <>
                            <div key={index} className={`card bg-white mb-3  ${ride.currentPassengersCount == 0 ? 'disabled-main-card' : ''}`}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    zIndex: "1"
                                }}>
                                    <button className="position-absolute" style={{ marginTop: ride.usersMilestoneCount > 0 ? "50px" : "70px", opacity: ride.currentPassengersCount == 0 ? "0.8" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                                        <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                                    </button>
                                    <div className="position-absolute row justify-content-center">
                                        {ride.usersMilestoneCount <= 3 && [...Array(ride.usersMilestoneCount)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "82px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                        {ride.usersMilestoneCount > 3 && [...Array(1)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "82px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {<p className="position-absolute zi10" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "95px" }}>
                                        {ride.usersMilestoneCount === 0 ? t("NO_STOP") : `${t("STOP")} (${ride.usersMilestoneCount})`}
                                    </p>}
                                </div>
                                <div className={`card-body cursor-pointer px-0 p-3 ${ride.currentPassengersCount == 0 ? 'disabled-main-content' : ''}`}>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-auto d-flex flex-row">
                                                    <img onClick={() => navigate("/user/" + ride.driverId)}
                                                        style={{ height: 40, width: 40 }}
                                                        className="cursor-pointer avatar-img rounded-circle border border-white border-3 shadow"
                                                        src={ride.driver.profilePhotoId == null ? "/assets/images/avatar/01.jpg" : httpSmartClient.getPhotoUrl(ride.driver.profilePhotoId)}
                                                        alt=""
                                                    ></img>
                                                    <div>
                                                        <h6 onClick={() => navigate("/user/" + ride.driverId)} style={{ fontWeight: "bold", color: "black", marginLeft: "5px", fontSize: "13px", marginTop: "3px" }}>{ride.driver.firstName} {ride.driver.lastName} </h6>
                                                        {ride.driver.ridesAverageRating != null &&
                                                            <p className="mb-1" style={{ marginLeft: "5px", fontSize: "12px", marginTop: "-5px" }}>
                                                                <i className="fas fa-star text-warning me-1"></i>
                                                                {Number.isInteger(ride.driver.ridesAverageRating) ? ride.driver.ridesAverageRating + ".0" : ride.driver.ridesAverageRating.toFixed(1)}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto d-flex flex-row">
                                            {ride.currentPassengersCount == 0 && <div className="disabled-main-overlay"><p className="full">{t("FULL")}</p></div>}
                                            {ride.currentPassengersCount != 0 && <> <h5 style={{ textAlign: "right", fontWeight: "bold", fontSize: "17px", color: "#900fc8" }}>{ride.price}</h5><h5 style={{ textAlign: "right", fontWeight: "bold", fontSize: "16px", opacity: "80%", marginLeft: "5px" }} > {t("VALUTE")}</h5> </>}
                                        </div>
                                    </div>
                                    <div className="row" onClick={() => RideDetail(ride)} style={{ marginTop: "-5px" }}>
                                        <div className="col-auto text-start" style={{ maxWidth: "105px" }}>
                                            <h6 style={{ fontWeight: "bold", fontSize: "14px", marginTop: "17px", color: "black" }} >
                                                {ride.rideMilestoneFrom}</h6>
                                        </div>
                                        <div className="col">
                                            <div className="row mt-3">
                                                <div className="col-auto" style={{ marginLeft: "-10px" }}>
                                                    <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
                                                    <p style={{ fontSize: "12px", marginLeft: "0px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                                                </div>
                                                <div className="col" style={{ textAlign: "center", marginLeft: "-50px", marginRight: "-30px", marginTop: "-14px" }}>
                                                    <div className=" text-center">
                                                        <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                                            <hr style={{ border: "none", borderTop: "2px dashed grey" }} className="opacity-5 position-relative" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto " style={{ marginRight: "-10px" }}>
                                                    <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
                                                    <p style={{ fontSize: "12px", marginLeft: "-20px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto" style={{ maxWidth: "105px" }}>
                                            <h6 style={{ fontWeight: "bold", fontSize: "14px", marginTop: "17px", color: "black" }}>
                                                {ride.rideMilestoneTo}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row mb-1 mt-1" onClick={() => RideDetail(ride)} style={{ marginTop: "-5px", marginBottom: "-10px", justifyContent: "space-between" }}>
                                        <div className="col-5 d-flex flex-row">
                                            <svg width="21" height="20" style={{ marginLeft: "-3px" }} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.16675 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.8333 1.91663V4.79163" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.85425 8.7113H20.1459" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M20.625 8.14579V16.2916C20.625 19.1666 19.1875 21.0833 15.8333 21.0833H8.16667C4.8125 21.0833 3.375 19.1666 3.375 16.2916V8.14579C3.375 5.27079 4.8125 3.35413 8.16667 3.35413H15.8333C19.1875 3.35413 20.625 5.27079 20.625 8.14579Z" stroke="#656565" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5408 13.1292H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5408 16.0042H15.5494" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.9956 13.1292H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.9956 16.0042H12.0042" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.44876 13.1292H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.44876 16.0042H8.45736" stroke="#656565" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p style={{ fontSize: "14px", marginLeft: "4px", marginTop: "1px" }}>{moment(ride.startDateTime).format("DD.MM.yyyy")} </p>
                                        </div>
                                        <div className="col-3 d-flex flex-row" style={{ justifyContent: "center" }}>
                                            <svg width="21" height="20" style={{ marginLeft: "-2px" }} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p style={{ fontSize: "14px", marginLeft: "4px", marginTop: "1px" }}>{ride.driverVehicle.vehicleModel.name} </p>
                                        </div>
                                        <div className="col-4 d-flex flex-row" style={{ justifyContent: "end" }}>
                                            {[...Array(ride.maxPassengersCount - ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                            {[...Array(ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <p style={{ fontSize: "14px", marginLeft: "4px", marginTop: "1px" }}>{ride.rideMilestoneFrom} → {ride.rideMilestoneTo}  </p>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    )
                })}
            <PagingComponent
                className="pagination-bar"
                currentPage={data.pageNumber ?? 0}
                totalCount={data.totalCount ?? 0}
                pageSize={data.pageSize ?? 0}
                onPageChange={onPageChange}
            />
        </>
    )
})

export default CatalogPageMainComponentMobile
