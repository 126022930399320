import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import userProfileStore from 'data/stores/UserProfileStore';
import { useForm } from 'react-hook-form';
import { httpSmartClient } from 'config/httpClient';
import { toastify } from 'utils/toastify';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import chatStore from 'data/stores/ChatStore';
import "../../../../layout/common.css"
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import ReactQuill from 'react-quill';
import { UsersService, AuthService, ConnectionsService } from 'data/services';
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import UpdateEmailModal from './modals/UpdateEmailModal';
import globalStore from 'data/stores/GlobalStore';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import DOMPurify from 'dompurify';
import VerificationModal from './modals/VerificationModal';

const MyPersonalInfo = observer(() => {
    const { t } = useTranslation();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [preview, setPreview] = useState()
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState();
    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState();
    const [showVerificationModal, setShowVerificationModal] = useState();
    const [isMobile, setIsMobile] = useState();
    const [biography, setBiography] = useState();
    const [isValid, setIsValid] = useState();
    const [isVerified, setIsVerified] = useState(false);
    const [isCloseButtonDisabled, setIsClosedButtonDisabled] = useState(false);
    const navigate = useNavigate();
    let user = userProfileStore.user;
    const size = useWindowSize();
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
        setError,
        getValues,
        clearErrors
    } = useForm();
    const reactQuillRef = React.useRef();
    const checkPhoneValidation = () => {
        const data = getValues();
        if (data.phoneNumber == "" || data.phoneNumber == undefined) {
            setError('phoneNumber', {
                type: "manual",
                message: t('PHONE_NUMBER_IS_REQUIRED')
            })
        }
    }
    const fetchUpdatedUser = async () => {
        const response = await UsersService.getById(user.id);
        if (response.isSuccess) {
            const updatedUser = response.data;
            if (updatedUser.profilePhoto) {
                updatedUser.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(updatedUser.profilePhoto);
            }
            else if (updatedUser.profilePhotoId) {
                updatedUser.profilePhotoUrl = httpSmartClient.getPhotoUrl(updatedUser.profilePhotoId);
            }
            userProfileStore.setUser(updatedUser);
            setIsVerified(updatedUser.isVerifiedPhoneNumber);
            if (chatStore.chatMessages.length > 0) {
                chatStore.updateProfilePhotoId(response.data.profilePhotoId, response.data.id)
            }
        }
        else {
            console.log(response.error);
        }
    }

    function cutContent(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const textContent = doc.body.textContent || "";
        let truncatedContent = content;
        if (textContent.length > 300) {
          truncatedContent = textContent.substring(0, 300);
        }
        const newDoc = parser.parseFromString(`<div>${truncatedContent}</div>`, 'text/html');
        truncatedContent = newDoc.body.innerHTML;
        return truncatedContent;
      }
    useEffect(() => {
        if (user == null)
            navigate("sign-in")
        if (user.profilePhotoId != null) {
            setPreview(user.profilePhotoUrl)
        }
        else {
            setPreview("/assets/images/avatar/01.jpg")
        }
        reset(user);
        setIsVerified(user.isVerifiedPhoneNumber);
        setBiography(user.biography);
        setValue("fullName", `${user.firstName} ${user.lastName}`);
        setValue("phoneNumber", `${user.phoneNumber}`);
        window.scrollTo(0, 0);
    }, [])

    const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() > 300 && event.key !== 'Backspace')
            event.preventDefault();
    };

    const sendVerificationNumberAndOpenModal = async () => {
        const response = await AuthService.addVerifyPhoneNumberRequest();
        if (response.isSuccess) {
            setShowVerificationModal(true);
        } else {
            toastify.error(t("ERROR_SENDING_VERIFICATION_CODE"));
        }
    }

    const handleCloseVerification = (bool) => {
        setIsClosedButtonDisabled(bool);
    };

    const onSubmit = async (data) => {
        const firstName = data.fullName.substring(0, data.fullName.indexOf(" "));
        const lastName = data.fullName.substring(data.fullName.indexOf(" ") + 1);
        const newUserData = { ...user, phoneNumber: data.phoneNumber?.replace(/\s/g, ''), profilePhoto: data.picture, firstName: firstName, lastName: lastName, biography: data.biography }
        setFormSubmitting(true);
        const response = await UsersService.edit(newUserData);
        setFormSubmitting(false);
        if (response.data) {
            fetchUpdatedUser();
            toastify.success(t("SUCCESSFULLY_UPDATED_INFORMATION"));
            chatStore.changeRender();
        }
        else {
            toastify.error(t("ERROR_UPDATING_INFORMATION"));
        }
    }

    const signOut = () => {
        AuthService.signOut();
        ConnectionsService.connectionEnd();
        navigate("/sign-in");
    }

    const handlePhotoUpload = async (e) => {
        const file = e.target.files[0];
        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)
        setValue("picture", file)
    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        ['clean']
    ];

    useEffect(() => {
        if (size.width < 768) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false)
        }
        if (size.width >= 1400) {
            const outlet = document.getElementById("outlet");
            outlet.className = "w-75 col-md"
        }
        else {
            const outlet = document.getElementById("outlet");
            outlet.className = "w-75"
            outlet.style.marginBottom = "0px";
        }
        if (size.width >= 1200) {
            const header = document.getElementById("profileHeader")
            header.classList.remove("d-none")
            header.className = "profileHeader"
            const element = document.createElement("h6");
            element.innerText = t("MY_PROFILE")
            element.className = 'header-profile';
            header.appendChild(element)
            return () => header.removeChild(element);
        }
        else {
            const header = document.getElementById("profileHeader");
            header.classList.remove("profileHeader")

        }
    }, [size, globalStore.lng]);

    function sanitizeHTML(input) {
        const cleanHTML = DOMPurify.sanitize(input, {
            ALLOWED_TAGS: ['p', 'strong', 'em'],
            FORBID_TAGS: ['span', 'script'],
            FORBID_ATTR: ['style', 'classname'],
            USE_PROFILES: { html: true },
            ALLOW_DATA_ATTR: false,
            ALLOWED_ATTR: false,
            ALLOW_UNKNOWN_PROTOCOLS: false
        });
        return cleanHTML;
    }

    return (
        <>
            <Modal
                open={showUpdatePasswordModal}
                onClose={() => setShowUpdatePasswordModal(false)}
                closeIcon={globalStore.closeIcon}
                modalId='updateModals'
                classNames={{
                    modal: 'modal-size',
                    closeButton: "mt-1"
                }}
                style={{
                    maxWidth: '90%'
                }}
                center>
                <UpdatePasswordModal
                    onDiscard={() => setShowUpdatePasswordModal(false)}
                />
            </Modal>
            <Modal
                open={showUpdateEmailModal}
                closeIcon={globalStore.closeIcon}
                onClose={() => setShowUpdateEmailModal(false)}
                modalId='updateModals'
                classNames={{
                    modal: 'modal-size h-auto',
                    closeButton: "mt-1"
                }}
                center
            >
                <UpdateEmailModal
                    onDiscard={() => setShowUpdateEmailModal(false)}
                    setShowUpdateEmailModal={setShowUpdateEmailModal}
                />
            </Modal>
            <Modal
                open={showVerificationModal}
                onClose={isCloseButtonDisabled ? null : () => setShowVerificationModal(false)}
                closeIcon={globalStore.closeIcon}
                modalId='updateModals'
                classNames={{
                    modal: 'modal-size h-auto',
                    closeButton: "mt-1"
                }}
                center>
                <VerificationModal
                    userId={user.Id}
                    onDiscard={() => setShowVerificationModal(false)}
                    setShowVerificationModal={setShowVerificationModal}
                    onVerification={handleCloseVerification}
                    fetchUpdatedUser={fetchUpdatedUser}
                    phoneNumber={user.phoneNumber}
                />
            </Modal>

            {!isMobile ?
                <>
                    {showVerificationModal === true ? <div className='verificationModalMainTitle'>
                        <span className="d-flex pt-2 justify-content-center googleRoutesmainTitle textWhite">{t("PHONE_VERIFICATION_MAIN_TITLE")}</span>
                    </div> : ""}
                    <div className="card border user-information" style={{ marginLeft: "15px", marginTop: "10px" }}>
                        <div className="card-header">
                        <h5 className="card-header-title font" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>{t("USER_DATA")}</h5>
                        </div>  <div className="card-body mb-1">
                            <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                                <div className="col-12">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <label className="position-relative d-flex flex-column align-items-center justify-content-center" htmlFor="uploadfile-1" title="Replace this pic">
                                            <div className='d-flex align-items-end justify-content-center ms-4'>
                                                <img id="uploadfile-1-preview" className="profile-img rounded-circle border border-white border-3 shadow cursor-pointer" src={preview ? preview : "/assets/images/avatar/01.jpg"} />
                                                <svg className='pencil cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                                    <path d="M20 40.1035C31.0457 40.1035 40 31.1492 40 20.1035C40 9.05782 31.0457 0.103516 20 0.103516C8.9543 0.103516 0 9.05782 0 20.1035C0 31.1492 8.9543 40.1035 20 40.1035Z" fill="#900FC8" />
                                                    <path d="M12.1952 24.4925C12.0077 24.68 11.9023 24.9344 11.9023 25.1996V27.1494C11.9023 27.7016 12.3501 28.1494 12.9023 28.1494H14.8521C15.1173 28.1494 15.3717 28.044 15.5592 27.8565L25.1913 18.2244L21.8273 14.8604L12.1952 24.4925Z" fill="white" />
                                                    <path d="M27.7823 14.3626L25.6884 12.2676C25.5197 12.0999 25.2916 12.0059 25.0539 12.0059C24.8161 12.0059 24.588 12.0999 24.4194 12.2676L22.7773 13.9056L26.1414 17.2696L27.7823 15.6286C27.9491 15.4601 28.0426 15.2326 28.0426 14.9956C28.0426 14.7585 27.9491 14.5311 27.7823 14.3626Z" fill="white" />
                                                </svg>
                                            </div>
                                            <span className="mb-0 selectNewPhoto mt-3 cursor-pointer" htmlFor="uploadfile-1">{t("SELECT_NEW_PHOTO")}</span>
                                            <input id="uploadfile-1" className="form-control d-none" onChange={handlePhotoUpload} type="file" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{t("FULL_NAME")}:</label>
                                    <input
                                        {...register("fullName", {
                                            required: t("PLEASE_ENTER_FULL_NAME"),
                                            validate: (value) => {
                                                let splitted = value.split(" ");
                                                if (splitted.length < 2 || splitted[1] === '')
                                                    return t("Morate unijeti ime i prezime.");
                                                return true;
                                            }
                                        })}
                                        disabled
                                        type="text"
                                        className="form-control inputs b-none"
                                        placeholder="Enter your full name" />
                                    {errors.fullName && <span className="text-danger">{errors.fullName.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{t("PHONE_NUMBER")}:</label>
                                    <div>
                                        <IntlTelInput
                                            {...register("phoneNumber", {
                                                required: t("PHONE_NUMBER_IS_REQUIRED"),
                                                validate: {
                                                    matchPattern: (v) => {
                                                        let test = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/.test(v);
                                                        if (test || (v.includes("+") && v.indexOf("+") !== 0) || !isValid) {
                                                            return t("PHONE_NUMBER_VALIDATION")
                                                        }
                                                    }
                                                },
                                            })}
                                            containerClassName="intl-tel-input w-100 phoneInput"
                                            inputClassName="form-control bg-light border-0 "
                                            defaultCountry="ba"
                                            defaultValue={user.phoneNumber}
                                            onPhoneNumberChange={(isValid, value, fullNumber, extension) => {
                                                if (value.length < 7 || value.length > 15)
                                                    setIsValid(false);
                                                else if (value.split('+').length - 1 > 1)
                                                    setIsValid(false);
                                                else setIsValid(true);
                                                const dialCode = fullNumber.dialCode;
                                                let trimmedValue = value.startsWith(dialCode) ? value.substring(dialCode.length) : value;
                                                const phoneNumber = fullNumber.dialCode.concat(trimmedValue.replace(/^0+/, ''));
                                                setValue('phoneNumber', phoneNumber);
                                            }}
                                        />
                                        {user && !isVerified && <span className='purple cursor-pointer fw-600 fs16 verificationSpan mt-2' onClick={() => sendVerificationNumberAndOpenModal()}>{t("VERIFICATION")}</span>}
                                        {errors.phoneNumber && errors.phoneNumber.type == 'matchPattern' && <small className="text-danger">{t("PHONE_NUMBER_VALIDATION")}</small>}
                                        {errors.phoneNumber && errors.phoneNumber.type == 'required' && <small className="text-danger">{t("PHONE_NUMBER_IS_REQUIRED")}</small>}
                                    </div>
                                </div>
                                <div className={`${errors && errors.phoneNumber || errors.fullName ? "mt-0" : ""}`}>
                                    <label className="labels ">{t("BIOGRAPHY")}:</label>
                                    <ReactQuill
                                        className='b-none'
                                        defaultValue={biography && biography.toString()}
                                        onKeyDown={checkCharacterCount}
                                        ref={reactQuillRef}
                                        value={biography && biography.toString()}
                                        onChange={(value) => {
                                            const newValue = cutContent(value);
                                            let xss = sanitizeHTML(newValue);
                                            setBiography(xss.toString());
                                            setValue("biography", xss.toString())
                                        }}
                                        modules={{ toolbar: toolbarOptions }}
                                        style={{ height: "150px", paddingBottom: "50px", maxWidth:"800px" }} />
                                </div>

                                <div className="text-center mt-3">
                                    <button onClick={() => checkPhoneValidation()} disabled={isFormSubmitting} type="submit" className="btn-font btn w-100 btn-primary">
                                        {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                        {t("UPDATE_INFORMATION")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="card border info-email-password mt-5" style={{ marginLeft: "15px" }}>
                        <div className="card-header">
                            <h5 className="card-header-title font" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>{t("ACCESS_DATA")}</h5>
                        </div>
                        <div className="card-body">
                            <div className='d-flex flex-column'>
                                <div className='d-flex'>
                                    <label className='labels mb-1'>{t("EMAIL")}:</label>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='paragraphs ms-1'>{user && user.email}</p>
                                    <p className='paragraphs-purple cursor-pointer' style={{marginTop:"0px"}} onClick={() => setShowUpdateEmailModal(true)}>{t("UPDATE")}</p>
                                </div>
                            </div>
                            <div className='d-flex flex-column' style={{marginTop:"8px"}}>
                                <div className='d-flex'>
                                    <label className='labels mb-1 '>{t("PASSWORD")}:</label>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className='paragraphs ms-1'>*********</p>
                                    <p className='paragraphs-purple cursor-pointer' style={{marginTop:"0px"}} onClick={() => setShowUpdatePasswordModal(true)}>{t("UPDATE")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div className="d-flex w-100  pt-1 pb-5 bg-purple flex-column text-center" style={{marginTop:"20px"}}>
                        <p className='fs18 white' style={{ fontWeight: "400" }}>{t("MY_PROFILE")}</p>
                    </div>
                <div className='user-info-mobile-background mb-4' style={{marginTop:"-20px", borderRadius:"1.5em"}}>
                    <div className='user-information-mobile mt-4 p-3'>
                        <div className="card-header ps-1">
                            <h4 style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>{t("USER_DATA")}</h4>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-center">
                                    <label className="position-relative d-flex flex-column align-items-center justify-content-center" htmlFor="uploadfile-1" title="Replace this pic">
                                        <div className='d-flex align-items-end justify-content-center ms-4'>
                                            <img id="uploadfile-1-preview" className="profile-img rounded-circle border border-white border-3 shadow cursor-pointer" src={preview ? preview : "/assets/images/avatar/01.jpg"} />
                                            <svg className='pencil cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                                <path d="M20 40.1035C31.0457 40.1035 40 31.1492 40 20.1035C40 9.05782 31.0457 0.103516 20 0.103516C8.9543 0.103516 0 9.05782 0 20.1035C0 31.1492 8.9543 40.1035 20 40.1035Z" fill="#900FC8" />
                                                <path d="M12.1952 24.4925C12.0077 24.68 11.9023 24.9344 11.9023 25.1996V27.1494C11.9023 27.7016 12.3501 28.1494 12.9023 28.1494H14.8521C15.1173 28.1494 15.3717 28.044 15.5592 27.8565L25.1913 18.2244L21.8273 14.8604L12.1952 24.4925Z" fill="white" />
                                                <path d="M27.7823 14.3626L25.6884 12.2676C25.5197 12.0999 25.2916 12.0059 25.0539 12.0059C24.8161 12.0059 24.588 12.0999 24.4194 12.2676L22.7773 13.9056L26.1414 17.2696L27.7823 15.6286C27.9491 15.4601 28.0426 15.2326 28.0426 14.9956C28.0426 14.7585 27.9491 14.5311 27.7823 14.3626Z" fill="white" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 selectNewPhoto mt-3 cursor-pointer" htmlFor="uploadfile-1">{t("SELECT_NEW_PHOTO")}</p>
                                        <input id="uploadfile-1" className="form-control d-none" onChange={handlePhotoUpload} type="file" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mt-1">
                                <label className="labels mb-2">{t("FULL_NAME")}:</label>
                                <input
                                    {...register("fullName", {
                                        required: t("PLEASE_ENTER_FULL_NAME"),
                                        validate: (value) => {
                                            let splitted = value.split(" ");
                                            if (splitted.length < 2)
                                                return t("Morate unijeti ime i prezime.");
                                            return true;
                                        }
                                    })}
                                    disabled
                                    type="text"
                                    className="form-control inputs b-none"
                                    placeholder="Enter your full name" />
                                {errors.fullName && <span className="text-danger">{errors.fullName.message}</span>}
                            </div>
                            <div >
                                <label className="labels mb-2">{t("PHONE_NUMBER")}:</label>
                                <div className="col-12">
                                    <IntlTelInput
                                        {...register("phoneNumber", {
                                            required: t("PHONE_NUMBER_IS_REQUIRED"),
                                            validate: {
                                                matchPattern: (v) => {
                                                    const test = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/.test(v);
                                                    if (test || (v.includes("+") && v.indexOf("+") !== 0) || !isValid) {
                                                        return t("PHONE_NUMBER_VALIDATION")
                                                    }
                                                }
                                            },
                                        })}
                                        containerClassName="intl-tel-input w-100 phoneInput"
                                        inputClassName="form-control bg-light border-0 "
                                        defaultCountry="ba"
                                        defaultValue={user.phoneNumber}
                                        onPhoneNumberChange={(isValid, value, fullNumber, extension) => {
                                            if (value.length < 7 || value.length > 15)
                                                setIsValid(false);
                                            else if (value.split('+').length - 1 > 1)
                                                setIsValid(false);
                                            else setIsValid(true);
                                            const phoneNumber = fullNumber.dialCode.concat(value.replace(/^0+/, ''));
                                            setValue('phoneNumber', phoneNumber);
                                        }}
                                    />
                                    {user && !isVerified && <span className='purple cursor-pointer fw-600 fs16 verificationSpan mt-2' onClick={() => sendVerificationNumberAndOpenModal()}>{t("VERIFICATION")}</span>}
                                    {errors.phoneNumber && errors.phoneNumber.type == 'matchPattern' && <small className="text-danger">{t("PHONE_NUMBER_VALIDATION")}</small>}
                                    {errors.phoneNumber && errors.phoneNumber.type == 'required' && <small className="text-danger">{t("PHONE_NUMBER_IS_REQUIRED")}</small>}
                                </div>
                            </div>
                            <div>
                                <label className="labels ">{t("BIOGRAPHY")}:</label>
                                <ReactQuill
                                    className='b-none'
                                    value={getValues("biography")}
                                    onKeyDown={checkCharacterCount}
                                    ref={reactQuillRef}
                                    onChange={(value) => {
                                        let xss = sanitizeHTML(value);
                                        setBiography(xss.toString());
                                        setValue("biography", xss.toString())
                                    }}
                                    modules={{ toolbar: toolbarOptions }}
                                    style={{ height: "150px", paddingBottom: "50px" }} />
                            </div>
                            <div className="text-center mt-3">
                                <button onClick={() => checkPhoneValidation()} disabled={isFormSubmitting} type="submit" className="btn-font btn w-100 btn-primary">
                                    {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                    {t("UPDATE_INFORMATION")}
                                </button>

                            </div>
                        </form>
                    </div>
                    <div className="info-email-password-mobile bg-white mt-3 p-4">
                        <div className="card-header mb-4">
                            <h4 style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>{t("ACCESS_DATA")}</h4>
                        </div>
                        <div className="card-body">
                            <div className='d-flex flex-column'>
                                <div className='d-flex'>
                                    <label className='labels mb-1'>{t("EMAIL")}:</label>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <p className='paragraphs ms-1'>{user && user.email}</p>
                                    <p className='paragraphs-purple cursor-pointer' style={{marginTop:"0px"}} onClick={() => setShowUpdateEmailModal(true)}>{t("UPDATE")}</p>
                                </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='d-flex'>
                                    <label className='labels mb-1'>{t("PASSWORD")}:</label>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='paragraphs ms-1'>*********</p>
                                    <p className='paragraphs-purple cursor-pointer' style={{marginTop:"0px"}} onClick={() => setShowUpdatePasswordModal(true)}>{t("UPDATE")}</p>
                                </div>
                            </div>
                        </div>
                        <div className='user-info-signout-deactivate-div m-auto d-flex flex-column justify-content-between align-items-center mt-3'>
                            <a className={`text-danger`} style={{alignItems:"center", justifyContent:"center", display:"flex"}} onClick={() => navigate('/my-profile/delete-profile')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M17.8368 5.07509C16.5561 4.94782 15.2754 4.85236 13.9868 4.78077V4.77282L13.8118 3.73872C13.6924 3.00691 13.5174 1.90918 11.656 1.90918H9.57195C7.71852 1.90918 7.54352 2.95918 7.41625 3.73077L7.2492 4.74895C6.50943 4.79668 5.76966 4.84441 5.02988 4.916L3.40716 5.07509C3.07307 5.10691 2.83443 5.40122 2.86625 5.72736C2.89807 6.0535 3.18443 6.29213 3.51852 6.26032L5.14125 6.10122C9.30945 5.68759 13.5095 5.84668 17.7254 6.26827C17.7493 6.26827 17.7652 6.26827 17.789 6.26827C18.0913 6.26827 18.3538 6.03759 18.3856 5.72736C18.4095 5.40122 18.1708 5.10691 17.8368 5.07509Z" fill="#E76868" />
                                    <path d="M16.3732 7.39031C16.1823 7.19144 15.9198 7.08008 15.6493 7.08008H5.5948C5.32434 7.08008 5.05389 7.19144 4.87093 7.39031C4.68798 7.58917 4.58457 7.85962 4.60048 8.13803L5.09366 16.2994C5.18116 17.5085 5.29253 19.0199 8.06866 19.0199H13.1755C15.9516 19.0199 16.063 17.5164 16.1505 16.2994L16.6437 8.14599C16.6596 7.85962 16.5562 7.58917 16.3732 7.39031ZM11.9425 15.0346H9.29362C8.96753 15.0346 8.69707 14.7642 8.69707 14.438C8.69707 14.1119 8.96753 13.8414 9.29362 13.8414H11.9425C12.2687 13.8414 12.5391 14.1119 12.5391 14.438C12.5391 14.7642 12.2687 15.0346 11.9425 15.0346ZM12.6107 11.8528H8.63343C8.3073 11.8528 8.03684 11.5824 8.03684 11.2562C8.03684 10.9301 8.3073 10.6596 8.63343 10.6596H12.6107C12.9368 10.6596 13.2073 10.9301 13.2073 11.2562C13.2073 11.5824 12.9368 11.8528 12.6107 11.8528Z" fill="#E76868" />
                                </svg> 
                                <span className='delete ms-1' style={{fontSize:"14px"}}>{t('DEACTIVATE_PROFILE')}</span>
                            </a>
                            <a onClick={signOut} className="ms-1 d-flex align-items-center" href='#'><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M13.2122 14.385C12.9409 17.535 11.3222 18.8213 7.77841 18.8213L7.66466 18.8213C3.75341 18.8213 2.18716 17.255 2.18716 13.3438L2.18716 7.63879C2.18716 3.72754 3.75341 2.16129 7.66466 2.16129L7.77841 2.16129C11.2959 2.16129 12.9147 3.43004 13.2034 6.52754" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.8749 10.5L17.8324 10.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.8813 13.4316L18.8125 10.5004L15.8813 7.56914" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> <span className='sign-out ms-1' style={{fontSize:"14px"}}>{t("SIGN_OUT")}</span></a>
                        </div>
                    </div>
                </div>
                </>
            }
        </>
    )
});

export default MyPersonalInfo;