import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Flatpickr from "react-flatpickr"
import moment from "moment/moment"
import React from "react"
import { AppSettingsService } from "data/services"

export default function MilestoneUpdateTimeModal({
  rows,
  setRows,
  index,
  setIndex,
  setIsModalClosing,
  setMilestoneModalOpen
}) {
  const { t } = useTranslation();
  const [flatPickrDiv, setFlatPickrDiv] = useState(null);
  const [rowsCopy, setRowsCopy] = useState([]);
  const [minHoursRide, setMinHoursRide] = useState(0);

  useEffect(() => {
    setRowsCopy(JSON.parse(JSON.stringify(rows)));
  }, [rows]);

  useEffect(() => {
    const fetchAndSetMinHoursRide = async () => {
      const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
      setMinHoursRide(response.data.items[0].minHoursBeforeRide);
    };
    fetchAndSetMinHoursRide();
  }, [index]);

  useEffect(() => {
    setTimeout(() => {
      let el = document.getElementById(`${index}_autocomplete`);
      if (el) {
        el.focus();
      }
    }, 20);
  }, [index]);

  const updateDateTimeValue = (index, date) => {
    if (date != null) {
      rowsCopy[index].dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss').toString();
      validateRow(rowsCopy[index]);
      setRowsCopy(rowsCopy);
    }
  };

  const validateRow = (row) => {
    row.isDateTimeInvalid = !row.dateTime && "ERROR";
    const currentRowDateTime = moment.utc(new Date(row.dateTime));
    if (index !== 0) {
      const previousRowDateTime = moment.utc(new Date(rowsCopy[index - 1].dateTime));
      row.isCurrentDateTimeConflictedWithPrevious = currentRowDateTime.diff(previousRowDateTime, 'minutes') === 0;
    } else {
      row.rideDurationLessThanMinHours = Math.ceil(currentRowDateTime.diff(moment(), 'minutes', true)) < minHoursRide * 60;
    }
    const nextRowDateTime = rowsCopy[index + 1] && moment.utc(new Date(rowsCopy[index + 1].dateTime));
    row.isCurrentDateTimeConflictedWithNext = nextRowDateTime && currentRowDateTime.diff(nextRowDateTime, 'minutes') >= 0;
    row.isValid =
      !row.isDateTimeInvalid &&
      !row.isCurrentDateTimeConflictedWithPrevious &&
      !row.isCurrentDateTimeConflictedWithNext &&
      !row.rideDurationLessThanMinHours;
  };

  const updateDateTimeForMilestone = (index) => {
    if (!rowsCopy[index]) return;

    validateRow(rowsCopy[index]);
    if (!rowsCopy[index].isValid) {
      setRowsCopy([...rowsCopy]);
      return;
    }

    if (index === 0) {
      rowsCopy[index].IsStartMilestone = true;
    } else if (index === rows.length - 1) {
      rowsCopy[rows.length - 1].IsLastMilestone = true;
    } else {
      rowsCopy[index].IsStopMilestone = true;
    }

    rowsCopy[index].isSubmitted = true;

    setRows([...rowsCopy]);
    setIsModalClosing(true);
    setMilestoneModalOpen(false);
  };

  const getTitle = () => {
    if (index === 0) {
      return t("EDIT_DEPARTURE_TIME");
    }
    else if (index === rows.length - 1) {
      return t("EDIT_ARRIVAL_TIME");
    }
    else {
      return t("EDIT_STANDING_TIME");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const modal = document.getElementById(`flatPickrDiv_${index}`);
      setFlatPickrDiv(modal);
    }, 20);
  }, []);

  return (
    <>

      <style>
        {`
          .flatpickr-wrapper {
            width:100%!important;
          }
      @media (max-width: 575.98px)
      .flatpickr-calendar.hasTime {
          width: 100%!important;
          min-width: 100%!important;
          max-width: 100%!important;
      }
    `}
      </style>

      {rows != null && (<React.Fragment key={`add_${index}`}>
        <div className="mb-3">
          <p className="mt-1 ms-1 cardModalTitle fw-600 fs18">{getTitle()}</p>
        </div>
        <div className="card-body ms-1">

          <div className="mb-3">
            {rows && rows[0] && !rows.length - 1 && (
              <>
                <label className="form-label fw-500 gray  fs14 mt-1">{t("DATE")}:</label>
                <div className="col" id={`flatPickrDiv_${index}`} >
                  <img className="marginLeftMilestoneModalIcons h-20px modalIcons" src="/assets/images/calendar.svg" alt="calendar" />
                  <Flatpickr
                    key={index}
                    onChange={(selectedDates) => {
                      updateDateTimeValue(index, selectedDates[0]);
                    }}
                    onValueUpdate={(selectedDates) => {
                      if (selectedDates.length === 0) {
                        updateDateTimeValue(index, null);
                      }
                    }}
                    type="text"
                    className='form-control fs16 input-field input-address'
                    placeholder={t("SELECT_DATE_AND_TIME")}
                    options={{
                      minDate: (() => {
                        const minDate = index === 0
                          ? moment(new Date()).add(1, 'hour').startOf('hour').toDate()
                          : rows.at(index - 1)
                            ? new Date(rows[index - 1]?.dateTime)
                            : undefined;
                        return minDate;
                      })(),
                      maxDate: (() => {
                        const maxDate = rows.at(index + 1)
                          ? new Date(rows[index + 1]?.dateTime)
                          : undefined;
                        return maxDate;
                      })(),
                      disableMobile: true,
                      enableTime: true,
                      time_24hr: true,
                      static: true,
                      dateFormat: "d.m.Y H:i",
                      defaultDate: (() => {
                        const defaultDate = rows[index] && rows[index].dateTime
                          ? new Date(rows[index].dateTime)
                          : rows[index - 1] && rows[index - 1].dateTime
                            ? new Date(rows[index - 1].dateTime)
                            : new Date();
                        return defaultDate;
                      })(),
                    }}
                  />

                  {rowsCopy[index] && rowsCopy[index].isDateTimeInvalid && <div className="text-danger errors ms-2"> {t("CHOOSE_DATETIME")}</div>}
                  {rowsCopy[index] && rowsCopy[index].isCurrentDateTimeConflictedWithPrevious && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_CONFLICTED_WITH_PREV")} {rowsCopy[index - 1] && moment(rowsCopy[index - 1].dateTime).format("DD.MM.YYYY HH:mm")}
                    </div>
                  )}
                  {rowsCopy[index] && rowsCopy[index].rideDurationLessThanMinHours && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE_MODAL", { minHoursRide })}
                    </div>
                  )}
                  {rowsCopy[index] && rowsCopy[index].isCurrentDateTimeConflictedWithNext && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_CONFLICTED_WITH_NEXT")} {rowsCopy[index + 1] && moment(rowsCopy[index + 1].dateTime).format("DD.MM.YYYY HH:mm")}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btnPrimary w-100 h-45 btnForAddingMilestones" onClick={() => updateDateTimeForMilestone(index)}>
              {t("EDIT")}
            </button>
          </div>
        </div>
      </React.Fragment>)}
    </>
  )
}
