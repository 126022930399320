import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastify } from 'utils/toastify';
import Modal from 'react-responsive-modal';
import { useWindowSize } from '@uidotdev/usehooks';
import globalStore from 'data/stores/GlobalStore';
import userProfileStore from 'data/stores/UserProfileStore';
import { TravelPreferencesService, UserTravelPreferenceOptionsService } from 'data/services';
import { AddUserTravelPreferenceOption } from '../../dialogs';
import { UserTravelPreference } from '../components';

const MyTravelPreference = observer(() => {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [currentId, setCurrentId] = useState(false);
    const [render, setRender] = useState(false);
    const [currentUserTravelOption, setCurrentUserTravelOption] = useState();
    const [travelPreference, setTravelPreference] = useState();
    const user = userProfileStore.user;
    const [isMobile, setIsMobile] = useState();
    const size = useWindowSize();
    useEffect(() => {
        const api = async () => {
            const response = await TravelPreferencesService.getByUserId()
            setData(response.data)
        }
        api();
        window.scrollTo(0, 0);
    }, [render])
    const handleShowDialog = (id, userTravelPreferenceOption) => {
        setTravelPreference(data.filter(x => x.id === id)[0]);
        setShowDialog(true)
        setCurrentUserTravelOption(userTravelPreferenceOption)
        setCurrentId(id)
    }
    const handleSubmit = async (submitData) => {
        const Arr = data;
        var index = data.findIndex(x => x.id === submitData.travelPreferenceId)
        if (submitData.delete) {
            if (Arr[index].userTravelPreferenceOption != undefined) {
                const deleted = await UserTravelPreferenceOptionsService.delete(Arr[index].userTravelPreferenceOption.id)
                if (deleted.isSuccess) {
                    Arr[index].userTravelPreferenceOption = null;
                    setData(Arr)
                    setShowDialog(false);
                    toastify.success(t("SUCCESS_EDITING_YOUR_TRAVEL_PREFERENCE"))
                    return;
                }
                else {
                    toastify.error(t("ERROR_EDITING_YOUR_TRAVEL_PREFERENCE"))
                }
            }
        }
        if (index !== -1) {
            if (data[index].userTravelPreferenceOption != null) {
                const response = await UserTravelPreferenceOptionsService.edit({ Id: data[index].userTravelPreferenceOption.id, TravelPreferenceOptionId: submitData.id, UserId: user.id })
                if (response.isSuccess) {
                    setRender(prev => !prev)
                    toastify.success(t("SUCCESS_EDITING_YOUR_TRAVEL_PREFERENCE"))
                } else {
                    toastify.error(t("ERROR_EDITING_YOUR_TRAVEL_PREFERENCE"))
                }
            } else {
                const response = await UserTravelPreferenceOptionsService.add({ UserId: user.id, TravelPreferenceOptionId: submitData.id })
                if (response.isSuccess) {
                    setRender(prev => !prev)
                    toastify.success(t("SUCCESS_EDITING_YOUR_TRAVEL_PREFERENCE"))
                } else {
                    toastify.error(t("ERROR_EDITING_YOUR_TRAVEL_PREFERENCE"))
                }
            }
        }

        setShowDialog(false);
    }
    useEffect(() => {
        if (size.width < 768) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false)
        }
        if (size.width >= 1400) {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("w-50")
            outlet.className = "w-75 col-md"
        }
        else {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("w-75")
            outlet.className = "w-70"
        }
        if (size.width >= 1200) {
            const div = document.createElement('div')
            const header = document.getElementById("profileHeader")
            header.classList.remove("d-none")
            header.className = "profileHeader"
            const element = document.createElement("h6");
            element.innerText = t("MY_PROFILE")
            element.className = 'header-profile mb-0';
            div.appendChild(element)
            header.appendChild(div)
            return () => header.removeChild(div);
        }
        else {
            const header = document.getElementById("profileHeader");
            header.classList.remove("profileHeader")

        }
    }, [size, globalStore.lng])
    useEffect(() => {
        const outlet = document.getElementById("outlet");
        if (size.width < 1200) {
            outlet.style.minHeight = "300px";
            outlet.style.marginBottom = "-40px";
        }
        else {
            outlet.style.minHeight = "700px";
        }
        if (size.width < 768) {
            const travelPreferenceDiv = document.getElementById("travel-preference-div");
            travelPreferenceDiv.className = "user-info-mobile-background h-100 w-100";
            travelPreferenceDiv.style.minHeight = "300px";
            const header = document.getElementById("header-height");
            header.className = "my-vehicle-add-header-row header-height z-index-master";
            return () => {
                header.classList.remove("my-vehicle-add-header-row")
                header.classList.remove("z-index-master")
                travelPreferenceDiv.classList.remove("user-info-mobile-background")
            }
        }
    }, [size])
    return (
        <div className="user-info-mobile-background" id='travel-preference-div'>
            {isMobile && <div className="d-flex w-100  pt-1 pb-5 bg-purple flex-column text-center">
                <p className='fs18 white  fw-600'>{t("MY_TRAVEL_PREFERENCES")}</p>
                <p className='fs12 white  fw-600'>{t("MY_TRAVEL_PREFERENCES_DETAILS")}</p>
            </div>}
            {isMobile && <div style={{ position: "relative", marginTop: "-3px" }} className="d-flex w-100   pt-1 pb-5 bg-purple flex-column text-center">
            </div>}
            {!isMobile ?
                <div className='travel-preferences mx-3 d-flex flex-column gap-3'>
                    <div>
                        <h5 className='headers pt-4 mb-1 mx-3'>{t("MY_TRAVEL_PREFERENCES")}</h5>
                    </div>
                    <div className='d-flex flex-column gap-2  mx-3 pt-0'>
                        {data && data.map(x => {
                            return <UserTravelPreference key={x.id} data={x} showDialog={(id, travelPreferenceOption) => handleShowDialog(id, travelPreferenceOption ? travelPreferenceOption : "")} />
                        })}
                    </div>
                </div> :
                <div className='pt-3 bg-light travel-preferences-mobile' style={{borderRadius:"1.5em"}}>
                    <div className='d-flex flex-column gap-2 p-3 pt-0 bg-white mt-2' style={{ borderRadius: "20px", width: "90%", position: "relative", margin: "auto", boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
                        {data && data.map(x => {
                            return <UserTravelPreference key={x.id} isMobile={isMobile} data={x} showDialog={(id, travelPreferenceOption) => handleShowDialog(id, travelPreferenceOption ? travelPreferenceOption : "")} />
                        })}
                    </div>
                </div>
            }
            <Modal
                open={showDialog}
                onClose={() => setShowDialog(false)}
                closeIcon={globalStore.closeIcon}
                classNames={{
                    modal: 'pick-user-travel-preference-modal',
                    closeButton: isMobile ? "mt-3" : "mt-3 me-3"
                }}
                center
            >
                <AddUserTravelPreferenceOption
                    isMobile={isMobile}
                    title={t("CHOOSE_YOUR_TRAVEL_PREFERENCE")}
                    travelPreference={travelPreference}
                    onDiscard={() => setShowDialog(false)}
                    currentId={currentId}
                    currentUserTravelOption={currentUserTravelOption}
                    onConfirm={handleSubmit}
                />
            </Modal>
        </div>
    )
})

export default MyTravelPreference
