import moment from 'moment';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { httpSmartClient } from 'config/httpClient';
import globalStore from 'data/stores/GlobalStore';
import Modal from 'react-responsive-modal';
import ReviewUserModal from './ReviewUserModal';
import { useNavigate } from 'react-router-dom';

function ReviewModal({ ride, openReviewModel }) {
    const { t } = useTranslation();
    const [openReviewDialog, setOpenReviewDialog] = useState();
    const [data, setData] = useState();
    const [isDriver, setIsDriver] = useState();
    const navigate = useNavigate();
    const handleOpenReviewDialog = (data, isDriver) => {
        setData(data);
        setIsDriver(isDriver)
        setOpenReviewDialog(true)
    }
    return (
        <div className='p-2'>
            <div className='mb-4'>
                <h6 className='review-header'>{t("LEAVE_REVIEW_TO_PASSENGERS")}</h6>
            </div>
            <div className='row d-flex gap-3'>
                <div className='col'>
                    <p className='labels'>{t("RIDE")}:</p>
                    <div className=' d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 19" fill="none">
                            <path d="M12.2791 2.24023H6.72158C4.75033 2.24023 4.31491 3.2219 4.06158 4.42523L3.16699 8.70815H15.8337L14.9391 4.42523C14.6857 3.2219 14.2503 2.24023 12.2791 2.24023Z" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.4099 15.6905C17.497 16.6168 16.7528 17.4163 15.8028 17.4163H14.3145C13.4595 17.4163 13.3407 17.0522 13.1903 16.6009L13.032 16.1259C12.8103 15.4768 12.6678 15.0413 11.5278 15.0413H7.47447C6.33447 15.0413 6.16822 15.5322 5.9703 16.1259L5.81197 16.6009C5.66155 17.0522 5.5428 17.4163 4.6878 17.4163H3.19947C2.24947 17.4163 1.5053 16.6168 1.59238 15.6905L2.03572 10.8693C2.14655 9.68176 2.37613 8.70801 4.4503 8.70801H14.552C16.6261 8.70801 16.8557 9.68176 16.9666 10.8693L17.4099 15.6905Z" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.16667 6.33301H2.375" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.6247 6.33301H15.833" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.5 2.375V3.95833" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.3125 3.95801H10.6875" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.75 11.875H7.125" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.875 11.875H14.25" stroke="#7E7E7E" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <h6 className='ms-2 mb-0 me-1' style={{fontSize:"15px",fontWeight:"500",color:"black"}}>{ride.cityFrom}</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                            <path d="M7.4292 0.929688L10.9996 4.5001L7.4292 8.0705" stroke="#7E7E7E" strokeWidth="0.9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 4.5H10.8995" stroke="#7E7E7E" strokeWidth="0.9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <h6 className='ms-1 mb-0' style={{fontSize:"15px",fontWeight:"500",color:"black"}}>{ride.cityTo}</h6>
                    </div>
                </div>
                <div className='col'>
                    <div>
                        <p className='labels'>{t("DEPARTURE_TIME")}:</p>
                    </div>
                    <div className='d-flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21 17" fill="none">
                            <path d="M11.1963 3.30469L16.8593 8.96773L11.1963 14.6308" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 8.9668H16.7017" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M20.1245 1.50293L20.1245 15.4973" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <h6 className='ms-2' style={{fontSize:"15px",fontWeight:"500",color:"black"}}>{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}</h6>
                    </div>
                </div>
            </div>
            <hr className="mt-2 mb-3" style={{border:"1px solid black",width:"100%",marginLeft:"auto",marginRight:"auto"}}/>
            {ride.isDriver ?
                <div>
                    {ride.userRides && ride.userRides.map(x => {
                        return (
                            <div className='row d-flex justify-content-between' key={x.id}>
                                <div className='col d-flex mb-3 align-items-center cursor-pointer w-50' onClick={() => navigate(`/user/${x.userId}`)}>
                                    <img className='my-rides-image' src={x.user.profilePhotoId ? httpSmartClient.getPhotoUrl(x.user.profilePhotoId) : "/assets/images/avatar/01.jpg"} />
                                    <div className='d-flex flex-column'>
                                        <h5 className='ms-2 user-name mb-0'>{x.user.firstName + " " + x.user.lastName}</h5>
                                        <div className='d-flex align-items-center'>
                                            <p className='ms-2 mb-0 me-1 labels'>{x.rideMilestoneFrom.locationName.toString().length < 15 ? x.rideMilestoneFrom.locationName: x.rideMilestoneFrom.locationName.substring(0,15) + "..." }</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 9 7" fill="none">
                                                <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='labels ms-1 mb-0' >{x.rideMilestoneTo.locationName.toString().length < 15 ? x.rideMilestoneTo.locationName: x.rideMilestoneTo.locationName.substring(0,15) + "..."}</p>
                                        </div>
                                    </div>
                                </div>
                                {!x.driverHasReviewOnPassenger ?
                                    <div className='col-auto d-flex cursor-pointer align-items-center' onClick={() => handleOpenReviewDialog(x, false)}>
                                        <h6 className='active mb-1'>{t("LEAVE_A_REVIEW")}</h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M6.33984 2.89035L10.9582 7.50868C11.5036 8.0541 11.5036 8.9466 10.9582 9.49202L6.33984 14.1104" stroke="#900FC8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    :
                                    <div className='col-auto d-flex align-items-center gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                            <path d="M8.20934 1.51092L9.44121 3.99501C9.60919 4.34081 10.0571 4.67249 10.4351 4.736L12.6679 5.11003C14.0957 5.34997 14.4317 6.39442 13.4028 7.42475L11.667 9.1749C11.373 9.4713 11.212 10.0429 11.303 10.4522L11.7999 12.6187C12.1919 14.3336 11.289 14.997 9.78417 14.1007L7.6914 12.8516C7.31344 12.6258 6.69051 12.6258 6.30556 12.8516L4.21279 14.1007C2.71495 14.997 1.80505 14.3266 2.19701 12.6187L2.69395 10.4522C2.78494 10.0429 2.62396 9.4713 2.33 9.1749L0.594187 7.42475C-0.4277 6.39442 -0.0987362 5.34997 1.32911 5.11003L3.56186 4.736C3.93282 4.67249 4.38077 4.34081 4.54875 3.99501L5.78061 1.51092C6.45254 0.163025 7.54442 0.163025 8.20934 1.51092Z" fill="#F7C32E" />
                                        </svg>
                                        <span className='routes'>{t("HAS_REVIEW_RATING")}{x.driversReviewRatingOnPassenger}.0</span>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                :
                <div>
                </div>
            }
            <Modal
                open={openReviewDialog}
                onClose={() => setOpenReviewDialog(false)}
                closeIcon={globalStore.closeIcon}
                modalId='reviewModals'
                classNames={{
                    modal: 'reviewUserModal',
                    closeButton:"mt-2 me-2"
                }}
                center
            >
                <ReviewUserModal
                    data={data}
                    isDriver={isDriver}
                >
                </ReviewUserModal>
            </Modal>
        </div>
    )
}

export default ReviewModal
