import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { httpSmartClient } from 'config/httpClient';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useWindowSize } from '@uidotdev/usehooks';
import Modal from "react-responsive-modal";
import Select, { components } from 'react-select';
import globalStore from 'data/stores/GlobalStore';
import { PagingComponent } from 'components/layout/components';
import { UsersService, RidesService, AuthService, ChatsService } from 'data/services';
import { ReviewModal } from '../user/userChunks/modals';
import ReviewDriverModal from '../user/userChunks/modals/ReviewDriverModal';
import { useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';
import { reactSelectCustomStyle } from 'assets/styles/react-select-customstyle';

export default function UserPageDesktop() {
  const {
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const [rides, setRides] = useState([]);
  const [data, setData] = useState([]);
  const [pagingData, setPagingData] = useState({ pageNumber: 1, pageSize: 5, filter: null, sort: null });
  const [ride, setRide] = useState([]);
  const [showReviewDialog, setShowReviewDialog] = useState();
  const [searchParams, setSearchParams] = useSearchParams("");
  const [filter, setFilter] = useState();
  const [sort, setSort] = useState();
  var tranCode = searchParams.get("TranCode");
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState();
  const [notificationSeen, setNotificationSeen] = useState(false);
  const size = useWindowSize();
  const [tab, setTab] = useState(0);
  const [ridesStatusCount, setRidesStatusCount] = useState();
  const activeRef = useRef();
  const cancelledRef = useRef();
  const completedRef = useRef();
  const [isMobile, setIsMobile] = useState();
  const [sortBySelect, setSortBySelect] = useState();
  const [showDriverReviewModal, setShowDriverReviewModal] = useState();

  const sortBy = [
    { value: "Newest", label: t("NEWEST") },
    { value: "Oldest", label: t("OLDEST") },
    { value: "Highest", label: t("HIGHEST") },
    { value: "Lowest", label: t("LOWEST") }
  ];
  const handleSorting = (e) => {
    setRides([])
    setSortBySelect(e.value)
    setPagingData(prev => {
      return {
        pageNumber: 1,
        pageSize: 5
      }
    })
    if (e.value === "Newest") {
      setSort(0);
    } else if (e.value === "Oldest") {
      setSort(1);
    } else if (e.value === "Highest") {
      setSort(2);
    } else if (e.value === "Lowest") {
      setSort(3);
    }
    else {
      setRides(rides)
    }
  }
  const currentUser = AuthService.getCurrentUser();
  const RideDetail = (ride) => {
    navigate({ pathname: `/rides/${ride.rideId}/${ride.rideMilestoneFromId}/${ride.rideMilestoneToId}` });
  }

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
    fetchAndSetData();
  }

  const changeTabs = (ref, tab) => {
    setTab(tab)
    setFilter(null)
    setSort(null)
    setSortBySelect()
    setActiveFilter();
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

  }

  useEffect(() => {
    const fetch = async () => {
      const response = await UsersService.getById(userId);
      if (response.isSuccess) {
        let dri = response.data;
        if (dri.profilePhoto) {
          dri.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(dri.profilePhoto);
        }
        else if (dri.profilePhotoId) {
          dri.profilePhotoUrl = httpSmartClient.getPhotoUrl(dri.profilePhotoId);
        }
        setUser(dri);
      }
      else {
        window.location = "/*";
      }
    }
    fetch();
    window.scrollTo(0, 0);

  }, [userId]);
  const handleChatNavigate = async (driverId) => {
    if (currentUser == null) {
      navigate({ pathname: '/sign-in' });
      return;
    }
    const response = await ChatsService.getChatGuidBetweenUsers({ UserOneId: user.id, UserTwoId: currentUser.Id })
    navigate(`/chat/${response.data}`)
  }

  useEffect(() => {
    fetchAndSetData();
  }, [tab, filter, sort]);

  const fetchAndSetData = async () => {
    setIsLoading(true);
    var result = await RidesService.getRidesAndUserRidesByUser({ ...pagingData, driverId: userId, rideStatus: tab, filter: filter, sort: sort });
    if (result.isSuccess && result.data.items) {
      setPagingData(prev => {
        return {
          ...prev,
          totalCount: result.data.items.length > 0 ? result.data.items[0].totalCount : 0
        }
      })
      setRidesStatusCount(result.data.items.length > 0 ? result.data.items[0].totalCount : 0)
      setRides(result.data.items);
      setData(result.data.items);
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  };


  useEffect(() => {
    if (size.width < 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, [size]);

  useEffect(() => {
    const element = document.getElementsByClassName("user-info-mobile-background")
    Array.from(element).forEach(x => {
      x.className = "user-info-mobile-background h-100 w-100"
    })
    return () => {
      Array.from(element).forEach(x => {
        x.classList.remove("h-100");
        x.classList.remove("user-info-mobile-background");
        x.classList.remove("w-100");
      })
    }
  }, [])

  return (
    <>
      <div className='row p-0 m-0 text-center' >
        <div style={{ marginTop: "-60px", height: "73px", fontWeight: "500", backgroundColor: "#900fc8" }} className='white ' ><h5 className="white fw-600 fs22 mt-4 ms-5">{t("USER_PROFILE")}</h5></div>
      </div>
      <div className='container p-0 w-100' style={{ minHeight: "550px", zIndex: "2" }}>
        <div className=' row px-0 justify-content-center' style={{ marginLeft: "0px", marginRight: "9px" }}>
          {user != null && <div style={{ zIndex: "100", width: "350px" }}>
            <div style={{ marginTop: "-50px", marginLeft: "24px", paddingTop: "20px", paddingBottom: "30px" }} className='card custom-shadow-user-page px-3 row justify-content-center '>
              <div className="col-12 d-flex  justify-content-center  mb-2 mb-sm-0">
                <div style={{ position: "relative", display: "inline-block", height: "110px", width: "110px", minWidth: "110px" }}>
                  <img width={"100%"} height={"auto"} style={{ display: "block" }} className={`avatar-img ${user.isVerifiedPhoneNumber ? 'profile__photo__isVerified' : ''} rounded-circle `} src={user && user.profilePhotoId ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} alt="no profile picture" />
                  {user.isVerifiedPhoneNumber && <img style={{ height: "25px", position: "absolute", top: "80px", left: "78px" }} src='../assets/images/verified.png'></img>}
                </div>
              </div>
              <div className='col-12 pb-0  mb-0 pt-2 d-flex justify-content-center'>
                <p className='fw-600 mb-2 fs18 black' >{user.firstName + " " + user.lastName}</p>
              </div>
              <div className='col-12 px-0 justify-content-center small text-center'>
                <p className='mb-0' dangerouslySetInnerHTML={{ __html: user && user.biography ? user.biography : "" }} />
              </div>
              {user && currentUser?.Id != user.id && <div className='col-12 mt-3 d-flex justify-content-center small text-center'>
                <a onClick={() => handleChatNavigate()} className='purple  cursor-pointer fw-700'>
                  <svg className='m-1 ms-n2' xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M19.2491 5.46875V9.93123C19.2491 11.0425 18.8816 11.9787 18.2254 12.6262C17.5779 13.2825 16.6416 13.65 15.5304 13.65V15.2337C15.5304 15.8287 14.8654 16.1875 14.3754 15.855L13.5267 15.295C13.6054 15.0237 13.6404 14.7262 13.6404 14.4112V10.85C13.6404 9.06502 12.4504 7.875 10.6654 7.875H4.72414C4.60164 7.875 4.4879 7.88377 4.37415 7.89252V5.46875C4.37415 3.2375 5.86165 1.75 8.0929 1.75H15.5304C17.7616 1.75 19.2491 3.2375 19.2491 5.46875Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.6404 10.85V14.4112C13.6404 14.7262 13.6054 15.0237 13.5267 15.295C13.2029 16.5812 12.1354 17.3862 10.6654 17.3862H8.2854L5.64289 19.145C5.24914 19.4163 4.72414 19.1275 4.72414 18.655V17.3862C3.83164 17.3862 3.0879 17.0888 2.57165 16.5725C2.04665 16.0475 1.74915 15.3037 1.74915 14.4112V10.85C1.74915 9.18752 2.78165 8.04127 4.37415 7.89252C4.4879 7.88377 4.60164 7.875 4.72414 7.875H10.6654C12.4504 7.875 13.6404 9.06502 13.6404 10.85Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className='fw-500'>{t("SEND_MESSAGE")}</span></a>
              </div>}
            </div>
            <div className='card custom-shadow-user-page row ps-2 py-2 mt-3' style={{ marginLeft: "24px" }}>
              <div className='row p-1 justify-content-center'>
                <div style={{ borderRight: "2px solid lightgrey" }} className='row col-8 p-1 ps-4  align-items-center'>
                  <svg className='col-auto p-0' xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                    <path d="M22.4335 3.08398H14.571C11.796 3.08398 11.1793 4.4715 10.8247 6.18275L9.56055 12.2261H27.4439L26.1797 6.18275C25.8251 4.4715 25.2085 3.08398 22.4335 3.08398Z" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29.665 22.0776C29.7884 23.3881 28.74 24.5135 27.3988 24.5135H25.3021C24.0996 24.5135 23.93 24.0048 23.7142 23.3573L23.4829 22.6943C23.1746 21.7847 22.9742 21.168 21.3555 21.168H15.6359C14.0325 21.168 13.7858 21.8618 13.5083 22.6943L13.2771 23.3573C13.0613 23.9893 12.8917 24.5135 11.6892 24.5135H9.59253C8.25128 24.5135 7.20294 23.3881 7.32628 22.0776L7.95836 15.2635C8.11253 13.5831 8.43627 12.2109 11.3654 12.2109H25.6258C28.555 12.2109 28.8788 13.5831 29.0329 15.2635L29.665 22.0776Z" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.559 8.86523H8.43359" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M28.5668 8.86523H27.4414" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.7969 16.6973H15.1423" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.8652 16.6973H25.2107" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.5 26.209V27.7507" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.5 32.375V33.9167" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.62565 27.75L3.08398 33.9167" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M32.375 27.75L33.9167 33.9167" stroke="black" strokeWidth="1.98214" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <div className='justify-self-center col-auto ps-2 d-flex flex-column'>
                    <p className='fs12 fw-400 m-0 d-inline' >{t("TOTAL_LISTINGS")}</p>
                    <p className='fw-600 fs18 mb-0 black d-inline' style={{ marginTop: "1px", color: "#292D32" }} >{user.totalRidesAsDriver + user.totalRidesAsPassenger}</p>
                  </div>
                </div>
                <div className=' row col-3 ms-4 align-items-center' style={{ paddingRight: "0px", paddingLeft: "0px", paddingBottom: "0px", paddingTop: "4px" }}>
                  <svg className='col-auto align-self-start p-0' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M10.5549 1.29976L12.1387 4.49359C12.3547 4.93818 12.9306 5.36463 13.4166 5.44629L16.2872 5.92718C18.123 6.23567 18.555 7.57853 17.2321 8.90325L15.0004 11.1534C14.6224 11.5345 14.4154 12.2695 14.5324 12.7957L15.1714 15.5812C15.6753 17.7861 14.5144 18.639 12.5796 17.4867L9.88895 15.8807C9.403 15.5903 8.60209 15.5903 8.10714 15.8807L5.41644 17.4867C3.49065 18.639 2.32078 17.777 2.82473 15.5812L3.46366 12.7957C3.58064 12.2695 3.37367 11.5345 2.99571 11.1534L0.763955 8.90325C-0.5499 7.57853 -0.126947 6.23567 1.70885 5.92718L4.57953 5.44629C5.05648 5.36463 5.63242 4.93818 5.84839 4.49359L7.43222 1.29976C8.29612 -0.433253 9.69997 -0.433253 10.5549 1.29976Z" fill="#F7C32E" />
                  </svg>
                  <p className=' col-auto fs18  ps-1 d-inline fw-600' style={{ marginTop: "-3px", color: "#7E7E7E" }} >{user.ridesAverageRating.toFixed(1)}</p>
                  <p className='fw-400 fs12 col-auto  ps-0 pe-0 d-inline' style={{ paddingBottom: "2px" }}>{t("LEAVE_REVIEW")}: {user.ridesRatingCount}</p>
                </div>
                <div onClick={() => navigate("ratings")} className='col-1  cursor-pointer d-flex'>
                  <svg className='col-auto align-self-center ps-2 m-0' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M6.33984 2.88937L10.9582 7.50771C11.5036 8.05312 11.5036 8.94562 10.9582 9.49104L6.33984 14.1094" stroke="#900FC8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div className='card custom-shadow-user-page row p-1 mt-3 pb-2' style={{ marginLeft: "24px" }}>
              <div className='row py-0 px-2 my-2'>
                <p className='black small mb-0 pt-1 fw-500 ms-1'>{t("TRAVEL_PREFERENCES")}</p>
              </div>
              {user && user.userTravelPreferenceOptions.length == 0 &&
                <div className='row py-0 px-2 mb-2'>
                  <p className='small ms-1'>{t("NO_TRAVEL_PREFERENCES")}.
                  </p>
                </div>
              }
              {user && user.userTravelPreferenceOptions && user.userTravelPreferenceOptions.map((preference, i) => (
                <div className='row ps-4 pe-0' key={i}>
                  <div className='col-auto mb-2 d-flex ps-0 align-items-center justify-content-start ms-0 mt-1'>
                    <img width={"20px"} className='' src={`${process.env.REACT_APP_API}/Photos/${preference.travelPreferenceOption.travelPreference.iconId}`}></img>
                    <p className='ps-2 pe-0 fw-500' style={{ fontSize: "14px" }}>
                      {t(preference.travelPreferenceOption.name)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>}
          <div className="w-75 col-md">
            <div className=" my-rides mt-5 p-3 pe-3 overflow-scroll" >
              <div className="card-body  p-0">
                <div className={`tabs-div mb-2 mt-0 gap-4 d-flex`}>
                  <a className={`mb-0 line-height text-nowrap cursor-pointer tabs-normal ${tab === 0 ? "active tab-active" : ""}`} onClick={(e) => changeTabs(activeRef, 0)} ref={activeRef}>
                    <div className="d-flex align-items-center">
                      <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 0 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.58301 10.0002V8.7669C7.58301 7.17524 8.70801 6.53357 10.083 7.32524L11.1497 7.9419L12.2163 8.55857C13.5913 9.35024 13.5913 10.6502 12.2163 11.4419L11.1497 12.0586L10.083 12.6752C8.70801 13.4669 7.58301 12.8169 7.58301 11.2336V10.0002Z" stroke={`${tab === 0 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {t("UPCOMING_RIDES")} {tab === 0 && !isLoading ? `(${ridesStatusCount})` : ""}
                    </div>
                  </a>
                  <a className={`mb-0 line-height text-nowrap cursor-pointer tabs-normal ${tab === 1 ? "active tab-active" : ""}`} onClick={() => changeTabs(cancelledRef, 1)} ref={cancelledRef}>
                    <div className="d-flex align-items-center">
                      <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.6416 12.3583L12.3583 7.6416" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.3583 12.3583L7.6416 7.6416" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {t("CANCELLED_RIDES")} {tab === 1 && !isLoading ? `(${ridesStatusCount})` : ""}
                    </div>
                  </a>
                  <a className={`mb-0 line-height text-nowrap cursor-pointer tabs-normal ${tab === 2 ? "active tab-active" : ""}`} onClick={() => changeTabs(completedRef, 2)} ref={completedRef}>
                    <div className="d-flex align-items-center">
                      <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 2 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.4585 9.99993L8.81683 12.3583L13.5418 7.6416" stroke={`${tab === 2 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {t("COMPLETED_RIDES")} {tab === 2 && !isLoading ? `(${ridesStatusCount})` : ""}
                    </div>
                  </a>
                </div>
                <div className="justify-content-between sorting-filter mt-4 mb-2">
                  {ridesStatusCount > 0 &&
                    <div className="col-12 d-flex justify-content-end">
                      <div className="d-flex align-items-center gap-1" style={{ zIndex: 100 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M6.38201 14.5209L2.82617 10.9722" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.38281 2.47925V14.5209" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.6172 2.47925L14.173 6.028" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.6172 14.5209V2.47925" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Select
                          value={sortBy.filter(s => s.value === sortBySelect)}
                          options={sortBy}
                          styles={reactSelectCustomStyle}
                          placeholder={t("CHOOSE")}
                          className="basic mselect react-select-price"
                          components={{ SingleValue: ({ children, ...props }) => (<components.SingleValue {...props}>{children}</components.SingleValue>) }}
                          onChange={(e) => handleSorting(e)}
                        />
                      </div>
                    </div>
                  }
                </div>
                {isLoading && <BeatLoader color="#900fc8" size={10} className='text-center' />}
                {rides && !isLoading && tab === 0 && rides.map((ride, index) => {
                  const toDate = moment.utc(new Date(ride.startDateTime))
                  const fromDate = moment.utc(new Date(ride.endDateTime))
                  var minutes = fromDate.diff(toDate, "minutes")
                  var hours = fromDate.diff(toDate, "hours");
                  var min = minutes % 60;
                  ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                  return (
                    ride.rideStatus == 'Upcoming' &&
                    <div onClick={() => RideDetail(ride)} className={`card custom-shadow-user-page p-2 mb-3 bg-white cursor-pointer ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: "1"
                      }}>
                        <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-3px" : "17px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                          <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                        </button>
                        <div className="position-absolute row justify-content-center">
                          {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                          {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                        </div>
                        {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "40px" }}>
                          {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                        </p>}
                      </div>
                      <div className="row px-2" onClick={() => RideDetail(ride)}>
                        <div className="col-auto pe-2 text-start" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityFrom}</h6>
                        </div>
                        <div className="col px-0">
                          <div className="row mt-3">
                            <div className="col-auto text-end pe-0 zi10">
                              <svg className="p-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className='text-start' style={{ fontSize: "13px", marginRight: "-17px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                            </div>
                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                              <div className=" text-center">
                                <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                  <hr style={{ border: "none", borderTop: "2px dashed grey", marginLeft: "-3px" }} className="opacity-5 position-relative" />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto zi10 ps-0 text-start">
                              <svg className="p-1 ps-0 pe-0 me-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className='text-end' style={{ fontSize: "13px", marginLeft: "-15px", paddingRight:"1px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto ps-2" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityTo}</h6>
                        </div>
                      </div>
                      <div className="d-flex gap-5 justify-content-start ms-3 div-info" >
                        <div className='d-flex'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                        </div>
                        {/* <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                            <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small"> {ride.countPassengers + t("PASSENGERS_2")}</span>
                        </div> */}

                        <div className="d-flex" style={isMobile ? {} : {position:"absolute", marginLeft:"200px"}}>
                          {[...Array(ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                          {[...Array(Math.abs(ride.maxPassengersCount - ride.countPassengers))].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                        </div>
                        {ride.isDriver && <div className="d-flex" style={isMobile ? {} : {position:"absolute", marginLeft:"350px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 23 22" fill="none">
                            <g clipPath="url(#clip0_1529_6528)">
                              <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1529_6528">
                                <rect width="22.47" height="21.09" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                        </div>}
                      </div>
                      <hr className="my-3" style={{ border: "1px solid black", width: "96%", marginLeft: "auto", marginRight: "auto" }} />
                      <div className="d-flex ms-4 time-cancel-ride-mobile">
                        <div className={`d-flex w-100 ms-3 justify-content-between align-items-between ride-card-below h-100`}>
                          <div className="d-flex gap-6 ride-card-below-time" onClick={() => RideDetail(ride)}>
                            <div className="d-flex flex-column">
                              <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                  <g clipPath="url(#clip0_503_15992)">
                                    <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_503_15992">
                                      <rect width="16" height="13" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="date-time-rides">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="labels-small">{t("TIME_END")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                  <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="date-time-rides">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                })}
                {!isLoading && tab === 0 && rides.filter(ride => ride.rideStatus === 'Upcoming').length === 0 &&
                  <div className='d-flex justify-content-between mb-4 pe-4'>
                    <div className="w-100 text-center"><span className="labels">{t("NO_ACTIVE_RIDES")}</span></div>
                  </div>}
                {rides && !isLoading && tab === 1 && rides.map((ride, index) => {
                  const toDate = moment.utc(new Date(ride.startDateTime))
                  const fromDate = moment.utc(new Date(ride.endDateTime))
                  var minutes = fromDate.diff(toDate, "minutes")
                  var hours = fromDate.diff(toDate, "hours");
                  var min = minutes % 60;
                  ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                  return (
                    ride.rideStatus == 'Cancelled' &&
                    <div onClick={() => RideDetail(ride)} className={`card p-2 custom-shadow-user-page mb-3 bg-white cursor-pointer ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: "1"
                      }}>
                        <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-3px" : "17px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                          <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                        </button>
                        <div className="position-absolute row justify-content-center">
                          {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                          {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                        </div>
                        {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "40px" }}>
                          {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                        </p>}
                      </div>
                      <div className="row px-2" >
                        <div className="col-auto pe-2 text-start" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityFrom}</h6>
                        </div>
                        <div className="col px-0">
                          <div className="row mt-3 ">
                            <div className="col-auto text-end pe-0 zi10">
                              <svg className="p-1 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className="text-start" style={{ fontSize: "13px", marginRight: "-10px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                            </div>
                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                              <div className=" text-center">
                                <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                  <hr style={{ border: "none", borderTop: "2px dashed grey", marginLeft: "-3px" }} className="opacity-5 position-relative" />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto text-start ps-0 zi10">
                              {/* <i style={{ fontSize: "10px" }} className="bi bi-circle"></i> */}
                              <svg className="p-1 ps-0 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className='text-end' style={{ fontSize: "13px", marginLeft: "-15px", paddingRight:"1px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto ps-2" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityTo}</h6>
                        </div>
                      </div>
                      <div className="d-flex gap-5 justify-content-start ms-3 div-info" >
                        <div className='d-flex'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                        </div>
                        {/* <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                            <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small"> {ride.countPassengers + t("PASSENGERS_2")}</span>
                        </div> */}
                        <div className="d-flex" style={isMobile ? {} : {position:"absolute", marginLeft:"200px"}}>
                          {[...Array(ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                          {[...Array(Math.abs(ride.maxPassengersCount - ride.countPassengers))].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                        </div>
                        <div className='d-flex' style={isMobile ? {} : {position:"absolute", marginLeft:"350px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 23 22" fill="none">
                            <g clipPath="url(#clip0_1529_6528)">
                              <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1529_6528">
                                <rect width="22.47" height="21.09" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                        </div>
                      </div>
                      <hr className="my-3" style={{ border: "1px solid black", width: "96%", marginLeft: "auto", marginRight: "auto" }} />
                      <div className="d-flex ms-4 time-cancel-ride-mobile" onClick={() => RideDetail(ride)}>
                        <div className="d-flex w-100 ms-3 justify-content-between align-items-center ride-card-below">
                          <div className="d-flex gap-6 ride-card-below-time">
                            <div className="d-flex flex-column">
                              <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                  <g clipPath="url(#clip0_503_15992)">
                                    <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_503_15992">
                                      <rect width="16" height="13" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="date-time-rides">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="labels-small">{t("TIME_END")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                  <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="date-time-rides">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )

                })}
                {!isLoading && tab === 1 && rides.filter(ride => ride.rideStatus === 'Cancelled').length === 0 &&
                  <div className='d-flex justify-content-between mb-4 pe-4'>
                    <div className="w-100 text-center"><span className="labels">{t("NO_CANCELLED_RIDES")}</span></div>
                  </div>}
                {rides && !isLoading && tab === 2 && rides.map((ride, index) => {
                  const toDate = moment.utc(new Date(ride.startDateTime))
                  const fromDate = moment.utc(new Date(ride.endDateTime))
                  var minutes = fromDate.diff(toDate, "minutes")
                  var hours = fromDate.diff(toDate, "hours");
                  var dateStart = moment.utc(ride.startDateTime).format("DD.MM.yyyy");
                  var dateEnd = moment.utc(ride.endDateTime).format("DD.MM.yyyy");
                  var min = minutes % 60;
                  ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                  return (
                    ride.rideStatus == 'Completed' &&
                    <div onClick={() => RideDetail(ride)} className={`card p-2 custom-shadow-user-page mb-3 bg-white cursor-pointer ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: "1"
                      }}>
                        <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-3px" : "17px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                          <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                        </button>
                        <div className="position-absolute row justify-content-center">
                          {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                          {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                            <>
                              <div className="col-auto" >
                                <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                              </div>
                            </>
                          )}
                        </div>
                        {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "40px" }}>
                          {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                        </p>}
                      </div>
                      <div className="row px-2" >
                        <div className="col-auto pe-2 text-start" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityFrom}</h6>
                        </div>
                        <div className="col px-0">
                          <div className="row mt-3">
                            <div className="col-auto text-end zi10 pe-0">
                              <svg className="p-1 ps-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className='text-start' style={{ fontSize: "13px", marginRight: "-17px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                            </div>
                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                              <div className=" text-center">
                                <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                  <hr style={{ border: "none", borderTop: "2px dashed grey", marginLeft: "-3px" }} className="opacity-5 position-relative" />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto zi10 ps-0 text-start">
                              {/* <i style={{ fontSize: "10px" }} className="bi bi-circle"></i> */}
                              <svg className="p-1 ps-0 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                              </svg>
                              <p className='text-end' style={{ fontSize: "13px", marginLeft: "-15px", paddingRight:"1px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto ps-2" style={{ maxWidth: "150px" }}>
                          <h6 className={`mt-3 pt-05 fw-500 fs16 black`}>{ride.cityTo}</h6>
                        </div>
                      </div>
                      <div className={`d-flex gap-5 justify-content-start align-items-center ms-3`} >
                        <div className="d-flex">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                        </div>
                        {/* <div className="d-flex align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                            <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#7E7E7E" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <span className="labels-small ms-1"> {ride.countPassengers}</span>
                          <span className="labels-small ms-1"> {t("PASSENGERS_2")}</span>
                        </div> */}
                        <div className="d-flex" style={isMobile ? {} : {position:"absolute", marginLeft:"200px"}}>
                          {[...Array(ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                          {[...Array(Math.abs(ride.maxPassengersCount - ride.countPassengers))].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          )}
                        </div>
                        <div className="d-flex" style={isMobile ? {} : {position:"absolute", marginLeft:"350px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 23 22" fill="none">
                            <g clipPath="url(#clip0_1529_6528)">
                              <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1529_6528">
                                <rect width="22.47" height="21.09" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                        </div>
                      </div>
                      <hr className="my-3" style={{ border: "1px solid black", width: "95%", marginLeft: "auto", marginRight: "auto" }} />
                      <div className={`d-flex ms-4 time-cancel-ride-mobile pb-2 ${isMobile ? "flex-column justify-content-between" : ""} ${!ride.isDriver ? "h-100px" : ""}`} >
                        <div className="d-flex w-100 ms-3 justify-content-between align-items-center ride-card-below h-100">
                          <div className="d-flex gap-6 ride-card-below-time">
                            <div className="d-flex flex-column" >
                              <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                  <g clipPath="url(#clip0_503_15992)">
                                    <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_503_15992">
                                      <rect width="16" height="13" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="date-time-rides">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                            <div className="d-flex flex-column" onClick={() => RideDetail(ride)}>
                              <span className="labels-small">{t("TIME_END")}:</span>
                              <div className="d-flex align-items-center gap-1 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                  <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="date-time-rides">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                              </div>
                            </div>
                          </div>

                        </div>
                        {ride.isDriver && isMobile &&
                          <div className="w-100 mt-4 d-flex justify-content-between align-items-center">
                            <div>
                              <label className="labels">{t("TOTAL_INCOME")}:</label>
                              <div className="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                  <g clipPath="url(#clip0_1529_6528)">
                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1529_6528">
                                      <rect width="22.47" height="21.09" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </div>
                            {ride.countPassengers > 0 && ride.sumOfDriverReviews == ride.countPassengers ?
                              <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                  <path d="M9.38211 1.65534L10.79 4.4943C10.9819 4.8895 11.4939 5.26856 11.9258 5.34115L14.4775 5.7686C16.1094 6.04282 16.4933 7.23647 15.3175 8.414L13.3337 10.4142C12.9977 10.7529 12.8137 11.4062 12.9177 11.874L13.4857 14.35C13.9336 16.3098 12.9017 17.068 11.1819 16.0437L8.79017 14.6162C8.35822 14.3581 7.6463 14.3581 7.20635 14.6162L4.81461 16.0437C3.1028 17.068 2.06292 16.3018 2.51087 14.35L3.07881 11.874C3.18279 11.4062 2.99881 10.7529 2.66285 10.4142L0.679071 8.414C-0.4888 7.23647 -0.112841 6.04282 1.51898 5.7686L4.0707 5.34115C4.49465 5.26856 5.00659 4.8895 5.19857 4.4943L6.60642 1.65534C7.37433 0.114886 8.62219 0.114886 9.38211 1.65534Z" fill="#F7C32E" />
                                </svg>
                                <span className="routes">{t("LEFT_EVERY_PASSENGER_REVIEW")}</span>
                              </div>
                              :
                              ride.countPassengers != 0 && ride.isFinalized == true &&
                              <div className="me-3" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path d="M8.86059 1.35241C9.48464 0.818729 10.5066 0.818729 11.1397 1.35241L12.5687 2.58267C12.8401 2.81785 13.3465 3.00778 13.7083 3.00778H15.2458C16.2045 3.00778 16.9913 3.79466 16.9913 4.75347V6.29125C16.9913 6.64402 17.1813 7.15961 17.4164 7.43097L18.6465 8.8601C19.1801 9.48423 19.1801 10.5064 18.6465 11.1395L17.4164 12.5687C17.1813 12.84 16.9913 13.3466 16.9913 13.7084V15.2462C16.9913 16.205 16.2045 16.9919 15.2458 16.9919H13.7083C13.3556 16.9919 12.8401 17.1818 12.5687 17.417L11.1397 18.6471C10.5157 19.1808 9.49369 19.1808 8.86059 18.6471L7.43161 17.417C7.16029 17.1818 6.6538 16.9919 6.29203 16.9919H4.72737C3.76868 16.9919 2.98184 16.205 2.98184 15.2462V13.6993C2.98184 13.3466 2.79192 12.84 2.56582 12.5687L1.34484 11.1305C0.820275 10.5064 0.820275 9.49328 1.34484 8.86915L2.56582 7.43097C2.79192 7.15961 2.98184 6.65307 2.98184 6.3003V4.75347C2.98184 3.79466 3.76868 3.00778 4.72737 3.00778H6.29203C6.64476 3.00778 7.16029 2.81785 7.43161 2.58267L8.86059 1.35241Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M8.24202 12.3497L9.45034 13.283C9.60868 13.4413 9.95869 13.5163 10.192 13.5163H11.667C12.1337 13.5163 12.642 13.1664 12.7587 12.6997L13.692 9.84966C13.8837 9.308 13.5337 8.83302 12.9504 8.83302H11.392C11.1587 8.83302 10.967 8.64141 11.0004 8.36641L11.192 7.11641C11.267 6.76641 11.0337 6.37471 10.6837 6.25805C10.3754 6.14138 9.98369 6.29968 9.82535 6.53301L8.22534 8.90799" stroke="#900FC8" strokeWidth="1.3125" strokeMiterlimit="10" />
                                  <path d="M6.25 12.3493V8.53269C6.25 7.98269 6.48334 7.79102 7.03334 7.79102H7.425C7.96667 7.79102 8.20834 7.98269 8.20834 8.53269V12.3493C8.20834 12.891 7.975 13.091 7.425 13.091H7.03334C6.48334 13.091 6.25 12.8993 6.25 12.3493Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="paragraphs-purple ms-2">{t("LEAVE_A_REVIEW")}</span>
                              </div>}
                          </div>}
                        {!ride.isDriver && isMobile && ride.hasReview &&
                          <div className='d-flex align-items-center justify-content-center gap-1 me-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                              <path d="M8.20934 1.51092L9.44121 3.99501C9.60919 4.34081 10.0571 4.67249 10.4351 4.736L12.6679 5.11003C14.0957 5.34997 14.4317 6.39442 13.4028 7.42475L11.667 9.1749C11.373 9.4713 11.212 10.0429 11.303 10.4522L11.7999 12.6187C12.1919 14.3336 11.289 14.997 9.78417 14.1007L7.6914 12.8516C7.31344 12.6258 6.69051 12.6258 6.30556 12.8516L4.21279 14.1007C2.71495 14.997 1.80505 14.3266 2.19701 12.6187L2.69395 10.4522C2.78494 10.0429 2.62396 9.4713 2.33 9.1749L0.594187 7.42475C-0.4277 6.39442 -0.0987362 5.34997 1.32911 5.11003L3.56186 4.736C3.93282 4.67249 4.38077 4.34081 4.54875 3.99501L5.78061 1.51092C6.45254 0.163025 7.54442 0.163025 8.20934 1.51092Z" fill="#F7C32E" />
                            </svg>
                            <span className="routes">{t("HAS_REVIEW_RATING")}{ride.passengersReviewRatingOnDriver}.0</span>
                          </div>
                        }
                      </div>
                    </div>
                  )

                })}
                {!isLoading && tab === 2 && rides.filter(ride => ride.rideStatus === 'Completed').length === 0 &&
                  <div className='d-flex justify-content-between mb-4 pe-4'>
                    <div className="w-100 text-center"><span className="labels">{t("NO_COMPLETED_RIDES")}</span></div>
                  </div>}
              </div>
              <PagingComponent
                className="pagination-bar"
                currentPage={pagingData.pageNumber ?? 0}
                totalCount={pagingData.totalCount ?? 0}
                pageSize={pagingData.pageSize ?? 0}
                onPageChange={onPageChange}
              />
              <Modal
                open={showReviewDialog}
                onClose={() => setShowReviewDialog(false)}
                closeIcon={globalStore.closeIcon}
                classNames={{
                  modal: 'reviewModal',
                }}
                center
              >
                <ReviewModal
                  ride={ride}
                >
                </ReviewModal>
              </Modal>
              <Modal
                open={showDriverReviewModal}
                onClose={() => setShowDriverReviewModal(false)}
                closeIcon={globalStore.closeIcon}
                classNames={{
                  modal: 'reviewUserModal',
                }}
                center
              >
                <ReviewDriverModal
                  data={ride}
                >
                </ReviewDriverModal>
              </Modal>
            </div >
          </div>
        </div>
      </div>
    </>
  )
}
