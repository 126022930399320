import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";

export default function RideMap({ ride, width, height }) {
    const [directions, setDirections] = useState(undefined);
    const [waypoints, setWaypoints] = useState([]);
    const [origin, setOrigin] = useState({});
    const [destination, setDestination] = useState({});
    const [selectedSummary, setSelectedSummary] = useState("");
    const [replaceSelectedSummary, setReplaceSelectedSummary] = useState("");

    const containerStyle = {
        width: width,
        height: height,
        margin: 'auto',
        borderRadius: "20px"
    };

    useEffect(() => {
        if (ride) {
            setSelectedSummary(ride.summary);
            setReplaceSelectedSummary(ride.summary?.replace("i","and"));
            setOrigin({ lat: ride.milestones[0].lat, lng: ride.milestones[0].lng });
            setDestination({ lat: ride.milestones[ride.milestones.length - 1].lat, lng: ride.milestones[ride.milestones.length - 1].lng });
            const stopWaypoints = ride.milestones.filter((milestone) => milestone.type === "Stop");
            const newArr = []
            stopWaypoints && stopWaypoints && stopWaypoints.forEach((milestone, index) => {
                newArr.push({
                    location: { lat: milestone.lat, lng: milestone.lng }
                })
            })
            setWaypoints(newArr);
        }
    }, [ride]);

    useEffect(() => {
        const getDirections = async () => {
            try {
                if (origin.lat && origin.lng) {
                    const directionsOptions = {
                        origin: origin,
                        destination: destination,
                        waypoints: waypoints,
                        travelMode: 'DRIVING',
                        provideRouteAlternatives: true,
                    };

                    const directionsService = new window.google.maps.DirectionsService();

                    directionsService.route(directionsOptions, (result, status) => {
                        if (status === 'OK') {
                            setDirections(result);
                        }
                    });
                }
            } catch (error) {
                throw error;
            }
        };

        if (origin && destination) {
            getDirections();
        }
    }, [origin, destination, waypoints]);

    return (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={10}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                }}
            >

                {directions && directions.routes && directions.routes.map((route, index) => {
                    return route.summary.includes(selectedSummary) || 
                    route.summary.replace("i","and").includes(replaceSelectedSummary) || 
                    selectedSummary.includes(route.summary) ||
                    replaceSelectedSummary.includes(route.summary.replace("i","and"))  ? (
                        <DirectionsRenderer
                            key={index}
                            directions={directions}
                            routeIndex={index}
                            options={{
                                polylineOptions: {
                                    strokeColor: "#039be5",
                                    strokeOpacity: 1.0,
                                    strokeWeight: 5
                                },
                            }}
                        />
                    )
                        : (<></>)
                }
                )}
            </GoogleMap>
        </>
    );
};