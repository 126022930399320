import 'react-responsive-modal/styles.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PublishRideMobile from "./PublishRideMobile";
import PublishRideDesktop from "./PublishRideDesktop";
import Modal from "react-responsive-modal";
import MilestoneModal from "./MilestoneModal";
import { AppSettingsService } from 'data/services';

export default function PublishRideWrapper() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [formData, setFormData] = useState();
    const [index, setIndex] = useState(0);
    const [driverVehicles, setDriverVehicles] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [step, setStep] = useState(1);
    const [selectedDriverVehicle, setSelectedDriverVehicle] = useState(null);
    const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
    const [routeKilometers, setRouteKilometers] = useState(null);
    const [maxPassengersCount, setMaxPassengersCount] = useState(1);
    const [conditions, setConditions] = useState();
    const [zeroResults, setZeroResults] = useState(false);
    const [selectedSummary, setSelectedSummary] = useState("");
    const [routeError, setRouteError] = useState();
    const [priceError, setPriceError] = useState();
    const [driverVehicleError, setDriverVehicleError] = useState();
    const [refreshRecommendedPrice, setRefreshRecommendedPrice] = useState(false);
    const [womenOnly, setWomenOnly] = useState(false);
    const [pricePerKilometers, setPricePerKilometers] = useState();
    const [isModalClosing, setIsModalClosing] = useState(false);
    const [flatPickrOpen,setFlatPickrOpen] = useState(false);
    useEffect(() => {
        const fetchPricePerKilometers = async (params) => {
            const pricePerKilometers = await AppSettingsService.getPricePerKilometers({ ...params });
            setPricePerKilometers(pricePerKilometers.data);
        };
        fetchPricePerKilometers();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const recalculateTotalPrice = () => {
        let sum = 0
        if (rows.length > 0) {
          rows.forEach((el) => {
            if (el.price) {
              sum += +el.price
            }
          })
          setTotalPrice(sum)
        }
      }    

    const onMilestoneModalClose = () => {
        setMilestoneModalOpen(false);
        setIsModalClosing(true);
        if (editingIndex !== -1) {
            rows[index] = rows[index].oldValue;
            setRows([...rows]);
        }
        if (rows.length <= 2 && editingIndex === -1) {
            navigate("/");
        } else {
            setTimeout(() => {
                let rowsCopy = [...rows];
                rowsCopy = rowsCopy.filter(r => r.isSubmitted);
                setRows(rowsCopy);
            }, 300);
        }
    }

    return (
        <>
            <div style={{ display: isMobile ? "block" : "none" }} >
                <PublishRideMobile
                    rows={rows} setRows={setRows}
                    setEditingIndex={setEditingIndex}
                    formData={formData} setFormData={setFormData}
                    setIndex={setIndex}
                    driverVehicles={driverVehicles} setDriverVehicles={setDriverVehicles}
                    totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                    milestoneModalOpen={milestoneModalOpen} setMilestoneModalOpen={setMilestoneModalOpen}
                    step={step} setStep={setStep}
                    selectedDriverVehicle={selectedDriverVehicle} setSelectedDriverVehicle={setSelectedDriverVehicle}
                    selectedRouteIndex={selectedRouteIndex} setSelectedRouteIndex={setSelectedRouteIndex}
                    routeKilometers={routeKilometers} setRouteKilometers={setRouteKilometers}
                    maxPassengersCount={maxPassengersCount} setMaxPassengersCount={setMaxPassengersCount}
                    conditions={conditions} setConditions={setConditions}
                    zeroResults={zeroResults} setZeroResults={setZeroResults}
                    selectedSummary={selectedSummary} setSelectedSummary={setSelectedSummary}
                    routeError={routeError} setRouteError={setRouteError}
                    driverVehicleError={driverVehicleError} setDriverVehicleError={setDriverVehicleError}
                    refreshRecommendedPrice={refreshRecommendedPrice} setRefreshRecommendedPrice={setRefreshRecommendedPrice}
                    womenOnly={womenOnly} setWomenOnly={setWomenOnly}
                    pricePerKilometers={pricePerKilometers}
                    priceError={priceError} setPriceError={setPriceError}
                    recalculateTotalPrice={recalculateTotalPrice}
                ></PublishRideMobile>
            </div>
            <div style={{ display: !isMobile ? "block" : "none" }} >
                <PublishRideDesktop
                    rows={rows} setRows={setRows}
                    setEditingIndex={setEditingIndex}
                    formData={formData} setFormData={setFormData}
                    index={index} setIndex={setIndex}
                    driverVehicles={driverVehicles} setDriverVehicles={setDriverVehicles}
                    totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                    milestoneModalOpen={milestoneModalOpen} setMilestoneModalOpen={setMilestoneModalOpen}
                    step={step} setStep={setStep}
                    selectedDriverVehicle={selectedDriverVehicle} setSelectedDriverVehicle={setSelectedDriverVehicle}
                    selectedRouteIndex={selectedRouteIndex} setSelectedRouteIndex={setSelectedRouteIndex}
                    routeKilometers={routeKilometers} setRouteKilometers={setRouteKilometers}
                    maxPassengersCount={maxPassengersCount} setMaxPassengersCount={setMaxPassengersCount}
                    conditions={conditions} setConditions={setConditions}
                    zeroResults={zeroResults} setZeroResults={setZeroResults}
                    selectedSummary={selectedSummary} setSelectedSummary={setSelectedSummary}
                    routeError={routeError} setRouteError={setRouteError}
                    driverVehicleError={driverVehicleError} setDriverVehicleError={setDriverVehicleError}
                    refreshRecommendedPrice={refreshRecommendedPrice} setRefreshRecommendedPrice={setRefreshRecommendedPrice}
                    womenOnly={womenOnly} setWomenOnly={setWomenOnly}
                    editingIndex={editingIndex}
                    pricePerKilometers={pricePerKilometers}
                    priceError={priceError} setPriceError={setPriceError}
                    recalculateTotalPrice={recalculateTotalPrice}
                ></PublishRideDesktop>
            </div>

            <Modal
                open={milestoneModalOpen}
                showCloseIcon={true}
                closeOnOverlayClick={false}
                blockScroll={false}
                onClose={onMilestoneModalClose} center
                classNames={
                    {
                        modal:`milestoneModal`,
                        modalAnimationIn : "modalAnimationIn"
                    }
                }
                
            >
    
                <MilestoneModal
                    editingIndex={editingIndex}
                    setEditingIndex={setEditingIndex}
                    formData={formData}
                    flatPickrOpen={flatPickrOpen}
                    setFlatPickrOpen={() => setFlatPickrOpen(prev => !prev)}
                    setFormData={setFormData}
                    rows={rows}
                    setRows={setRows}
                    index={index}
                    setIndex={setIndex}
                    driverVehicles={driverVehicles}
                    setDriverVehicles={setDriverVehicles}
                    setMilestoneModalOpen={setMilestoneModalOpen}
                    setRefreshRecommendedPrice={setRefreshRecommendedPrice}
                    setIsModalClosing={setIsModalClosing} isModalClosing={isModalClosing}
                    isMobile={isMobile}
                ></MilestoneModal>
            </Modal>
        </>
    );
}