import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { useWindowSize } from '@uidotdev/usehooks';
import moment from 'moment';
import Select from "react-select";
import { CountriesService, UsersService } from 'data/services';
import userProfileStore from 'data/stores/UserProfileStore';

function FinalizationDialogBankAccountInformation(props) {
    const { t } = useTranslation();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isMobile, setIsMobile] = useState();
    const [countries, setCountries] = useState();
    const [lastAccountNumber, setLastAccountNumber] = useState();
    const [filters, setFilters] = useState();
    const [defaultCountry, setDefaultCountry] = useState();
    const size = useWindowSize();

    useEffect(() => {
        if (size.width > 767) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }, [size.width])

    useEffect(() => {
        const getCountries = async () => {
            let result = await CountriesService.getByParams({ pageSize: 999, pageNumber: 1 });
            if (result.isSuccess && result.data.items) {
                setCountries(() => {
                    return result.data.items.map(x => {
                        return {
                            value: x.id,
                            label: x.name
                        }
                    })
                });
                const item = result.data.items.find((x) => x.abbreviation === 'BiH');
                if (item) {
                    setFilters({ label: item.name, value: item.id });
                    setValue('countryId', item.id);
                }
            }
        };
        const getAccountNumber = async () => {
            const response = await UsersService.getAccountNumberForLastFinalizedRide(userProfileStore.user.id);
            if (response.isSuccess && response.data) {
                setLastAccountNumber(response.data);
                setValue('accountNumber', response.data)
            }
        };
        getAccountNumber();
        getCountries();
        setValue('nameAccountOwner', `${userProfileStore.user.firstName} ${userProfileStore.user.lastName}`)
    }, []);

    const {
        formState: { errors },
        register,
        setValue,
        handleSubmit,
        getValues,
        clearErrors,
        setError
    } = useForm({ mode: "onChange" });

    const onSubmit = async (data) => {
        props.onConfirm(data);
    }

    return (
        <>
            {!isMobile ?
                <div className='bg-white h-100 p-2' style={{ maxHeight: "650px" }} id="finalizationDialog">
                    <div className='mb-3'>
                        <h5 className='black fs18 fw-600' >{t("FINALIZE_RIDE")}</h5>
                    </div>
                    <div>
                        <div className='row justify-content-between '>
                            <div className='col d-flex flex-column'>
                                <label className='labels fs13 fw-500 gray ms-0'>{t("RELACIJA")}:</label>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <div style={{ width: "20px", height: "100%", paddingBottom: "1px" }}>
                                        <svg width="19" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div style={{ marginLeft: "5px" }}>
                                        <p className='paragraphs mb-0' style={{ marginRight: "5px", display: "inline", wordWrap: "break-word" }}>
                                            {props.ride.cityFrom}
                                        </p>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px" }} width="10" height="10" viewBox="0 0 9 7" fill="none">
                                            <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className='paragraphs mb-0' style={{ display: "inline", wordWrap: "break-word" }}>
                                            {props.ride.cityTo}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col d-flex flex-column'>
                                <label style={{ fontSize: "13px", marginBottom: "7px" }} className='labels'>{t("TIME_START")}:</label>
                                <div className='d-flex gap-2 align-items-center'>
                                    <svg width="19" className='ms-2' height="18" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.4302 2.92993L21.5002 8.99993L15.4302 15.0699" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.5 9H21.33" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M1 1L1 16" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p className="paragraphs mb-0"> {moment.utc(props.ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</p>
                                </div>
                            </div>
                            <div className='col-3 d-flex flex-column'>
                                <label style={{ fontSize: "13px", marginRight: "0px", marginBottom: "7px" }} className='labels row justify-content-end'>{t("TOTAL_INCOME")}:</label>
                                <div className='d-flex gap-2 align-items-center justify-content-end'>
                                    <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1529_6528)">
                                            <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                            <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                            <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                            <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                            <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1529_6528">
                                                <rect width="22.47" height="21.09" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h5 className="BAM-big-purple "> {props.ride.totalEarningsForDriver > 0 ? props.ride.totalEarningsForDriver : "0"} </h5><h5 style={{ lineHeight: "normal", color: "grey" }} >{t("VALUTE")}</h5>
                                </div>
                            </div>
                        </div>
                        <hr style={{ border: "1px solid", marginTop: "10px", marginBottom: "20px" }}></hr>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col'>
                                    <div className=' d-flex justify-content-start'>
                                        <label className="labels mb-2">{t("FIRST_LAST_NAME")}:</label>
                                    </div>
                                    <input {...register("nameAccountOwner", { required: t("FIRST_LAST_NAME_REQUIRED") })} id='nameAccountOwner'
                                        className="form-control b-none inputs" placeholder={t("ENTER_FIRST_LAST_NAME")} name='nameAccountOwner' />
                                    {errors.nameAccountOwner && <span className="text-danger">{t("FIRST_LAST_NAME_REQUIRED")}</span>}
                                </div>
                                <div className='col'>
                                    <div className=' d-flex justify-content-start'>
                                        <label className="labels mb-2">{t("ACCOUNT_NUMBER")}:</label>
                                    </div>
                                    <input  {...register("accountNumber", { required: t("ACCOUNT_NUMBER_REQUIRED"), pattern: /^[0-9]+$/i })} id='accountNumber'
                                        className="form-control b-none inputs" placeholder={t("ENTER_ACCOUNT_NUMBER")} name='accountNumber' />
                                    {errors.accountNumber && errors.accountNumber.type === "required" && (
                                        <span className="text-danger">{t("ACCOUNT_NUMBER_REQUIRED")}</span>
                                    )}
                                    {errors.accountNumber && errors.accountNumber.type === "pattern" && (
                                        <span className="text-danger">{t("ACCOUNT_NUMBER_MUST_BE_NUMBER")}</span>
                                    )}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-md-6 col-sm-12 mb-1">
                                    <label className="labels mb-2">{t("COUNTRY")}:</label>
                                    <Select
                                        {...register("countryId", {
                                            required: t("COUNTRY_REQUIRED"),
                                        })}
                                        className="basic-select"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                backgroundColor: '#F3F3F3',
                                                border: 'none',
                                            })
                                        }}
                                        onChange={(e) => {
                                            clearErrors("countryId")
                                            setValue("countryId", e.value)
                                            setFilters({ value: e.value, label: e.label })
                                        }}
                                        placeholder={t("SELECT_COUNTRY")}
                                        options={countries}
                                        value={filters && filters.label ? { label: filters.label, value: filters.value } : null}
                                    />
                                    {errors.countryId && <span className="text-danger">{t("COUNTRY_REQUIRED")}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12 mb-1">
                                    <div className=' d-flex justify-content-start'>
                                        <label className="labels mb-2">{t("RESIDENTAL_ADDRESS")}:</label>
                                    </div>
                                    <input id='address' {...register("address", { required: t("ADDRESS_REQUIRED") })}
                                        className="form-control b-none inputs" placeholder={t("ENTER_ADDRESS")} name='address' />
                                    {errors.address && <span className="text-danger">{t("ADDRESS_REQUIRED")}</span>}
                                </div>
                            </div>
                            {filters && filters.label != 'Bosna i Hercegovina' &&
                                <div>
                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <div className=' d-flex justify-content-start'>
                                                <label className="labels mb-2">{t("Banka")}:</label>
                                            </div>
                                            <input {...register("bankName", { required: t("BANK_NAME_REQUIRED") })} id='bankName'
                                                className="form-control b-none inputs" placeholder={t("ENTER_BANK_NAME")} name='bankName' />
                                            {errors.bankName && <span className="text-danger">{t("BANK_NAME_REQUIRED")}</span>}
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <div className=' d-flex justify-content-start'>
                                                <label className="labels mb-2">{t("IBAN")}:</label>
                                            </div>
                                            <input {...register("IBAN", { required: t("IBAN_REQUIRED") })} id='IBAN'
                                                className="form-control b-none inputs" placeholder={t("BA12 1234 5678 1234 5678")} name='IBAN' />
                                            {errors.IBAN && <span className="text-danger">{t("IBAN_REQUIRED")}</span>}
                                        </div>
                                        <div className='col'>
                                            <div className=' d-flex justify-content-start'>
                                                <label className="labels mb-2">{t("SWIFT")}:</label>
                                            </div>
                                            <input  {...register("SWIFT", { required: t("SWIFT_REQUIRED") })} id='SWIFT'
                                                className="form-control b-none inputs" placeholder={t("ENTER_SWIFT")} name='SWIFT' />
                                            {errors.SWIFT && <span className="text-danger">{t("SWIFT_REQUIRED")}</span>}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='mt-4'>
                                    <input
                                        className="form-check-input  cursor-pointer"
                                        onClick={() => setButtonDisabled(prev => !prev)}
                                        type="checkbox"
                                        id='checkConfirm'
                                    />
                                    <label className='ms-2  cursor-pointer' htmlFor='checkConfirm'>{t("CONFIRM_RIDE_FINALIZATION")}</label>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col'></div>
                                <div className='col-auto'>
                                    <button className="btn text-secondary border-0 float-end mt-2" onClick={() => props.onDiscard()}>{t("DISCARD")}</button>
                                </div>
                                <div className='col-auto'>
                                    <button className={`${buttonDisabled ? "finalize-button-dialog-disabled-mobile" : "finalize-button-dialog-mobile"} text-white`} disabled={buttonDisabled} type='submit'>
                                        {t("FINALIZE_RIDE")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className='bg-white'>
                    <div className='mb-3'>
                        <h5>{t("FINALIZE_RIDE")}</h5>
                    </div>
                    <div className='d-flex align-items-center mb-3'>

                        <div className='d-flex flex-column gap-3'>
                            <div className='d-flex flex-column gap-2 '>
                                <label className='labels mb-0'>{t("RELACIJA")}:</label>
                                <div className='d-flex gap-2 align-items-center'>
                                    <svg width="19" height="18" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p className='paragraphs mb-0'>
                                        {props.ride.cityFrom}
                                    </p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 9 7" fill="none">
                                        <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p className='paragraphs mb-0'>
                                        {props.ride.cityTo}
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex gap-4'>
                                <div className='d-flex flex-column gap-1'>
                                    <label style={{ fontSize: "13px" }} className='labels mb-2'>{t("TIME_START")}:</label>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <svg width="19" className='ms-2' height="18" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.4302 2.92993L21.5002 8.99993L15.4302 15.0699" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.5 9H21.33" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M1 1L1 16" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className="paragraphs mb-0"> {moment.utc(props.ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-1'>
                                <label style={{ fontSize: "13px" }} className='labels'>{t("TOTAL_INCOME")}:</label>
                                <div className='d-flex gap-2 align-items-center'>
                                    <svg width="19" className='ms-1' height="18" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1529_6528)">
                                            <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                            <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                            <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                            <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                            <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1529_6528">
                                                <rect width="22.47" height="21.09" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h5 className="BAM-big-purple "> {props.ride.totalEarningsForDriver > 0 ? props.ride.totalEarningsForDriver : "0"} </h5><h5 style={{ color: "grey", lineHeight: "normal" }} >{t("VALUTE")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col'>
                                <div className=' d-flex justify-content-start'>
                                    <label className="labels mb-2">{t("FIRST_LAST_NAME")}:</label>
                                </div>
                                <input {...register("nameAccountOwner", { required: t("FIRST_LAST_NAME_REQUIRED") })} id='nameAccountOwner'
                                    className="form-control b-none inputs" placeholder={t("ENTER_FIRST_LAST_NAME")} name='nameAccountOwner' />
                                {errors.nameAccountOwner && <span className="text-danger">{t("FIRST_LAST_NAME_REQUIRED")}</span>}
                            </div>
                            <div className='col'>
                                <div className=' d-flex justify-content-start'>
                                    <label className="labels mb-2">{t("ACCOUNT_NUMBER")}:</label>
                                </div>
                                <input  {...register("accountNumber", { required: t("ACCOUNT_NUMBER_REQUIRED"), pattern: /^[0-9]+$/i })} id='accountNumber'
                                    className="form-control b-none inputs" placeholder={t("ENTER_ACCOUNT_NUMBER")} name='accountNumber' />
                                {errors.accountNumber && errors.accountNumber.type === "required" && (
                                    <span className="text-danger">{t("ACCOUNT_NUMBER_REQUIRED")}</span>
                                )}
                                {errors.accountNumber && errors.accountNumber.type === "pattern" && (
                                    <span className="text-danger">{t("ACCOUNT_NUMBER_MUST_BE_NUMBER")}</span>
                                )}
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-md-6 col-sm-12 mb-1">
                                <label className="labels mb-2">{t("COUNTRY")}:</label>
                                <Select
                                    {...register("countryId", {
                                        required: t("COUNTRY_REQUIRED"),
                                    })}
                                    className="basic-select"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: '#F3F3F3',
                                            border: 'none',
                                        })
                                    }}
                                    onChange={(e) => {
                                        clearErrors("countryId")
                                        setValue("countryId", e.value)
                                        setFilters({ value: e.value, label: e.label })
                                    }}
                                    placeholder={t("SELECT_COUNTRY")}
                                    options={countries}
                                    value={filters && filters.label ? { label: filters.label, value: filters.value } : null}
                                />
                                {errors.countryId && <span className="text-danger">{t("COUNTRY_REQUIRED")}</span>}
                            </div>
                            <div className="col-md-6 col-sm-12 mb-1 mt-3">
                                <div className=' d-flex justify-content-start'>
                                    <label className="labels mb-2">{t("RESIDENTAL_ADDRESS")}:</label>
                                </div>
                                <input id='address' {...register("address", { required: t("ADDRESS_REQUIRED") })}
                                    className="form-control b-none inputs" placeholder={t("ENTER_ADDRESS")} name='address' />
                                {errors.address && <span className="text-danger">{t("ADDRESS_REQUIRED")}</span>}
                            </div>
                        </div>
                        {filters && filters.label != 'Bosna i Hercegovina' &&
                            <div>
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <div className=' d-flex justify-content-start'>
                                            <label className="labels mb-2">{t("Banka")}:</label>
                                        </div>
                                        <input {...register("bankName", { required: t("BANK_NAME_REQUIRED") })} id='bankName'
                                            className="form-control b-none inputs" placeholder={t("ENTER_BANK_NAME")} name='bankName' />
                                        {errors.bankName && <span className="text-danger">{t("BANK_NAME_REQUIRED")}</span>}
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <div className=' d-flex justify-content-start'>
                                            <label className="labels mb-2">{t("IBAN")}:</label>
                                        </div>
                                        <input {...register("IBAN", { required: t("IBAN_REQUIRED") })} id='IBAN'
                                            className="form-control b-none inputs" placeholder={t("BA12 1234 5678 1234 5678")} name='IBAN' />
                                        {errors.IBAN && <span className="text-danger">{t("IBAN_REQUIRED")}</span>}
                                    </div>
                                    <div className='col'>
                                        <div className=' d-flex justify-content-start'>
                                            <label className="labels mb-2">{t("SWIFT")}:</label>
                                        </div>
                                        <input  {...register("SWIFT", { required: t("SWIFT_REQUIRED") })} id='SWIFT'
                                            className="form-control b-none inputs" placeholder={t("ENTER_SWIFT")} name='SWIFT' />
                                        {errors.SWIFT && <span className="text-danger">{t("SWIFT_REQUIRED")}</span>}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row'>
                            <div className='d-flex flex-column'>
                                <div className='ms-2 mt-4 cursor-pointer'>
                                    <input
                                        className="form-check-input  cursor-pointer"
                                        onClick={() => setButtonDisabled(prev => !prev)}
                                        type="checkbox"
                                        id='checkConfirm'
                                    />
                                    <label className='ms-2  cursor-pointer' htmlFor='checkConfirm'>{t("CONFIRM_RIDE_FINALIZATION")}</label>
                                </div>
                                <div className='d-flex justify-content-between mt-3 gap-3'>
                                    <button className="btn text-secondary border-0 mt-2" onClick={() => props.onDiscard()}>{t("DISCARD")}</button>
                                    <button className={`${buttonDisabled ? "finalize-button-dialog-disabled-mobile" : "finalize-button-dialog-mobile"} text-white fs15 ms-auto`} disabled={buttonDisabled} type='submit'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M7.94922 13.136C7.94922 14.3185 8.85672 15.2718 9.98422 15.2718H12.2851C13.2659 15.2718 14.0634 14.4377 14.0634 13.411C14.0634 12.2927 13.5776 11.8985 12.8534 11.6418L9.15922 10.3585C8.43505 10.1018 7.94922 9.70768 7.94922 8.58935C7.94922 7.56268 8.74672 6.72852 9.72755 6.72852H12.0284C13.1559 6.72852 14.0634 7.68185 14.0634 8.86435" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11 5.5V16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.7504 20.1659H8.25041C3.66707 20.1659 1.83374 18.3325 1.83374 13.7492V8.24919C1.83374 3.66585 3.66707 1.83252 8.25041 1.83252H13.7504C18.3337 1.83252 20.1671 3.66585 20.1671 8.24919V13.7492C20.1671 18.3325 18.3337 20.1659 13.7504 20.1659Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {t("FINALIZE_RIDE")}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div >
            }

        </>
    )
}

export default FinalizationDialogBankAccountInformation
